import { authService } from "../../../services/auth/authService";
import SxForm from "../../../forms/SxForm";
import SxField from "../../../forms/fields/SxField";
import { object, string } from "yup";
import SubmitButton from "../../../components/SubmitButton";
import { Link } from "react-router-dom";
import { toastsWithIntl } from "../../../services/toastService";
import { useTranslation } from "react-i18next";

function LostPasswordForm() {
  const { t } = useTranslation();
  const auth = authService();
  const { toastError, toastSuccess } = toastsWithIntl(["auth"]);

  return (
    <SxForm
      initialValues={{
        email: "",
      }}
      onSubmit={({ email }) => {
        return auth.lostPassword(email).then(
          () =>
            toastSuccess("auth:lost-password.SUCCESS", { duration: Infinity }),
          () => toastError("auth:lost-password.ERROR"),
        );
      }}
      validationSchema={object({
        email: string()
          .label(t("auth:lost-password.labels.EMAIL"))
          .email()
          .required(),
      })}
    >
      <h2 className="section-title">{t("auth:lost-password.FORM_TITLE")}</h2>
      <p className="cblock--xs body--30">
        {t("auth:lost-password.INSTRUCTIONS")}
      </p>
      <div className="lblock">
        <SxField
          name="email"
          placeholder={t("auth:lost-password.placeholders.EMAIL")}
        />
      </div>

      <div className="lblock--l --txt--center">
        <SubmitButton>{t("auth:lost-password.SUBMIT_BUTTON")}</SubmitButton>
      </div>
      <div className="cblock --txt--center">
        <Link className="link" to="/login">
          {t("auth:lost-password.BACK_TO_LOGIN")}
        </Link>
      </div>
    </SxForm>
  );
}

export default LostPasswordForm;
