import {
  Link,
  useLoaderData,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Profile } from "../../services/profile/profileModel";
import SuspenseVideoPlayer from "../../components/SuspenseVideoPlayer";
import { ReturnButton } from "../../components/ReturnButton";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { authService } from "../../services/auth/authService";
import Dialog from "../../components/Dialog";
import SxForm from "../../forms/SxForm";
import { boolean, object, string } from "yup";
import { transformEmptyToUndefined } from "../../react-helpers/yup";
import SxField from "../../forms/fields/SxField";
import SubmitButton from "../../components/SubmitButton";
import SxCheckbox from "../../forms/fields/SxCheckbox";
import { validateProfileAccessCodeRequest } from "../../services/profile/profileApi";

const { isLoggedIn } = authService();

const SharedPage = () => {
  const { t } = useTranslation();
  const { uid } = useParams();
  const { profile, video } = useLoaderData() as {
    profile: Profile;
    video: Promise<Blob> | null;
  };
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [invalidAccessCode, setInvalidAccessCode] = useState(false);

  return (
    <div className="layout_content --bg--organization">
      <div className="page-content container --wrap--m ">
        {isLoggedIn() && (
          <div>
            <ReturnButton forcedUrl="/organization/candidates">
              {t("organization:shared-profile.RETURN_TO_PROFILES")}
            </ReturnButton>
          </div>
        )}
        <div className="page_head lblock">
          <h1 className="page_title">
            {t("organization:shared-profile.PROFILE_OF", {
              name: profile.user!.firstname + " " + profile.user?.lastname,
            })}
          </h1>
          <Link
            className="link --danger --underlined"
            to={`${isLoggedIn() ? "/organization/contact" : "/contact"}?object=${encodeURIComponent(
              t("organization:shared-profile.REPORT_PROFILE_OBJECT", {
                profileId: profile.id,
                name: profile.user?.firstname + " " + profile.user?.lastname,
                userId: profile.user?.id,
              }),
            )}&message=${encodeURIComponent(t("organization:shared-profile.REASON"))}`}
          >
            {t("organization:shared-profile.REPORT_PROFILE")}
          </Link>
        </div>
        <div className="lrow --gap--l lblock--l">
          <div className="--stretch">
            <div className={"video-container --placeholder"}>
              {video && (
                <SuspenseVideoPlayer
                  videoPromise={video}
                  thumbnail={profile.thumbnail}
                />
              )}
            </div>
          </div>
        </div>
        <div className="lblock --txt--center">
          {isLoggedIn() ? (
            <Link
              className="btn--white"
              to="/organization/capsules"
              dangerouslySetInnerHTML={{
                __html: t("organization:shared-profile.CONNECTED_CTA"),
              }}
            />
          ) : (
            <Link
              className="btn--white"
              to={`/register/organization${searchParams.get("email") ? `?email=${encodeURIComponent(searchParams.get("email")!)}` : ""}`}
              dangerouslySetInnerHTML={{
                __html: t("organization:shared-profile.UNCONNECTED_CTA"),
              }}
            />
          )}
        </div>
      </div>
      {!video && (
        <Dialog className="--branded" modal>
          <>
            <div className="popup_head">
              <h2 className="popup_title">
                {t("organization:shared-profile.dialog.DIRECT_ACCESS_TITLE")}
              </h2>
            </div>
            <SxForm
              initialValues={{
                email: "",
                accessCode: "",
                cgu: false,
              }}
              onSubmit={(values) => {
                setInvalidAccessCode(false);
                return validateProfileAccessCodeRequest(
                  uid!,
                  values.accessCode,
                ).then(
                  () => {
                    navigate(
                      `.?accessCode=${values.accessCode}&email=${encodeURIComponent(values.email)}`,
                    );
                  },
                  () => {
                    setInvalidAccessCode(true);
                    return Promise.reject();
                  },
                );
              }}
              validationSchema={object({
                email: string()
                  .label(t("organization:shared-profile.dialog.labels.EMAIL"))
                  .email()
                  .transform(transformEmptyToUndefined)
                  .required(),
                accessCode: string()
                  .label(
                    t("organization:shared-profile.dialog.labels.ACCESS_CODE"),
                  )
                  .transform(transformEmptyToUndefined)
                  .required(),
                cgu: boolean()
                  .transform((v) => !!v)
                  .oneOf([true]),
              })}
            >
              <div className="popup_body">
                <SxField name="email" />
                <SxField name="accessCode" />
                {invalidAccessCode && (
                  <div className="field-error">
                    {t("organization:shared-profile.dialog.ERROR_INVALID_CODE")}
                  </div>
                )}
                <div className="lblock">
                  <SxCheckbox
                    name="cgu"
                    className="--checkicon"
                    label={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t(
                            "organization:shared-profile.dialog.labels.ACCEPT_CGU",
                          ),
                        }}
                      />
                    }
                  />
                </div>
                <div
                  className="lblock info --compact"
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "organization:shared-profile.dialog.INFOBOX_ACCESS_KEY",
                    ),
                  }}
                />
              </div>
              <div className="popup_footer">
                <Link className="btn" to={`/login?uid=${uid}`}>
                  {t("organization:shared-profile.dialog.BTN_LOGIN")}
                </Link>
                <SubmitButton type="submit" className="btn--white">
                  {t("organization:shared-profile.dialog.BTN_SUBMIT")}
                </SubmitButton>
              </div>
            </SxForm>
          </>
        </Dialog>
      )}
    </div>
  );
};

export default SharedPage;
