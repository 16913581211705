import Dialog from "../../../components/Dialog";
import { Capsule } from "../../../services/capsule/capsuleModel";
import { capsuleService } from "../../../services/capsule/capsuleService";
import { useTranslation } from "react-i18next";
import useReload from "../../../hooks/useReload";
import CapsuleForm from "./CapsuleForm";

const CapsuleEditDialog = ({
  onClose,
  capsule,
}: {
  onClose(): void;
  capsule: Capsule;
}) => {
  const { t } = useTranslation();
  const reload = useReload();
  const { updateCapsuleById } = capsuleService();

  return (
    <Dialog className="--branded" onClose={onClose}>
      <>
        <div className="popup_head">
          <h2 className="popup_title">
            {t("organization:capsule.EDIT_DIALOG_TITLE")}
          </h2>
        </div>

        <CapsuleForm
          capsule={capsule}
          onSubmit={(values) => {
            return updateCapsuleById(capsule.id, values).then(() => {
              reload();
              onClose();
            });
          }}
          onCancel={onClose}
        />
      </>
    </Dialog>
  );
};

export default CapsuleEditDialog;
