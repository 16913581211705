import { useRevalidator } from "react-router-dom";

function useReload() {
  const revalidator = useRevalidator();

  const reload = () => {
    revalidator.revalidate();
  };

  return reload;
}

export default useReload;
