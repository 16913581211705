import { Navigate, RouteObject, redirect } from "react-router-dom";
import CandidateDashboardPage from "../views/candidate/CandidateDashboardPage";
import ProfilePage from "../views/candidate/ProfilePage";
import {
  getAllProfilesRequest,
  getProfileRequest,
} from "../services/profile/profileApi";
import RecordPage from "../views/record/RecordPage";
import { promiseAllObject } from "../react-helpers/promise";
import { userService } from "../services/user/userService";
import { redirectOnCondition } from "./routerHelpers";
import ContactPage from "../views/contact/ContactPage";
import { authService } from "../services/auth/authService";
import { fetchRetriable } from "../react-helpers/fetch";

const { getLoggedUser } = userService();
const { isLoggedIn } = authService();

// NOTE: These routes are all the routes that will no longer be accessible once logged in
export const candidatesRoutes: RouteObject = {
  path: "", // public
  children: redirectOnCondition(
    [
      {
        index: true,
        loader() {
          return redirect("dashboard");
        },
      },
      {
        path: "dashboard",
        async loader() {
          return await promiseAllObject({
            profiles: getAllProfilesRequest(),
            loggedUser: getLoggedUser(),
          });
        },
        element: <CandidateDashboardPage />,
      },
      {
        path: "profiles/:profileId",
        loader: async ({ params }) => {
          const profile = await getProfileRequest(Number(params.profileId));
          let video = null;

          if (profile.videoLink) {
            video = (async (videoLink) => {
              const data = await fetchRetriable(videoLink, {
                method: "GET",
                retry: 3,
              });
              return new Blob(
                [new Uint8Array(await data.arrayBuffer()).reverse()],
                {
                  type: "video/mp4",
                },
              );
            })(profile.videoLink);
          }

          return {
            profile,
            video,
            loggedUser: await getLoggedUser(),
          };
        },
        element: <ProfilePage />,
      },
      {
        path: "profiles/:profileId/record",
        loader: async ({ params }) => {
          return await promiseAllObject({
            profile: getProfileRequest(Number(params.profileId)),
          });
        },
        errorElement: <Navigate to="./.." />,
        element: <RecordPage type="profile" />,
      },
      {
        path: "contact",
        element: <ContactPage />,
        async loader() {
          return {
            loggedUser: isLoggedIn() ? await getLoggedUser() : null,
          };
        },
      },
    ],
    async () => {
      const loggedUser = await getLoggedUser();
      return !!loggedUser.organizationId;
    },
    "/organization",
  ),
};
