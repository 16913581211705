import { object, string } from "yup";
import SxForm from "../../forms/SxForm";
import { Organization } from "../../services/organization/organizationModel";
import SxField from "../../forms/fields/SxField";
import SubmitButton from "../../components/SubmitButton";
import { organizationService } from "../../services/organization/organizationService";
import { useTranslation } from "react-i18next";
import useReload from "../../hooks/useReload";

const OrganizationSection = ({
  organization,
}: {
  organization: Organization;
}) => {
  const { t } = useTranslation();
  const { updateOrganization } = organizationService();
  const reload = useReload();
  return (
    <div>
      <h2 className="section_title">
        {t("accounts:organization.SECTION_TITLE")}
      </h2>

      {!organization.validationDate && (
        <div className="lblock">
          <div className="field-label"></div>

          <div className="info">
            {t("accounts:organization.VALIDATION_IN_PROGRESS")}
          </div>
        </div>
      )}

      <div className="lblock">
        <SxForm
          initialValues={{
            legalName: organization.legalName || "",
            address: organization.address || "",
          }}
          onSubmit={async (values) => {
            return updateOrganization(organization.id, values).then(() => {
              reload();
            });
          }}
          validationSchema={object({
            legalName: string()
              .label(t("accounts:organization.labels.LEGAL_NAME"))
              .required(),
            address: string()
              .label(t("accounts:organization.labels.ADDRESS"))
              .required(),
          })}
        >
          <SxField name="legalName" />
          <SxField name="address" />
          <div className="lblock--l --txt--center">
            <SubmitButton className="btn--2" type="submit">
              {t("accounts:organization.buttons.SAVE")}
            </SubmitButton>
          </div>
        </SxForm>
      </div>
    </div>
  );
};

export default OrganizationSection;
