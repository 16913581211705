export async function fetchRetriable(
  url: string,
  init?: RequestInit & { retry?: number },
): Promise<Response> {
  let retried = 0;
  const fetched = undefined;

  while (retried < (init?.retry ?? 3) && fetched === undefined) {
    try {
      return await fetch(url, init);
    } catch (err) {
      retried++;
    }
  }

  return Promise.reject(`Failed to fetch ${url} after ${retried} retries`);
}
