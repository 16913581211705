import i18n, { KeyPrefix, Namespace, ParseKeys, TOptions } from "i18next";
import { FallbackNs, initReactI18next } from "react-i18next";
import LngDetector from "i18next-browser-languagedetector";

import AUTH_FR from "./auth/fr.json";
import AUTH_EN from "./auth/en.json";
import ACCOUNTS_FR from "./accounts/fr.json";
import ACCOUNTS_EN from "./accounts/en.json";
import VALIDATION_FR from "./validation/fr.json";
import VALIDATION_EN from "./validation/en.json";
import CANDIDATE_FR from "./candidate/fr.json";
import CANDIDATE_EN from "./candidate/en.json";
import ORGANIZATION_FR from "./organization/fr.json";
import ORGANIZATION_EN from "./organization/en.json";
import COUNTRIES_FR from "./countries/fr.json";
import COUNTRIES_EN from "./countries/en.json";
import LANGUAGES_FR from "./languages/fr.json";
import LANGUAGES_EN from "./languages/en.json";
import RECORD_FR from "./record/fr.json";
import RECORD_EN from "./record/en.json";
import PUBLIC_FR from "./public/fr.json";
import PUBLIC_EN from "./public/en.json";
import COMMON_FR from "./common/fr.json";
import COMMON_EN from "./common/en.json";

import { setLocale } from "yup";
import { isDate, Locale } from "date-fns";
import { enGB, fr } from "date-fns/locale";
import { formatDate } from "../react-helpers/date";

export const i18nResources = {
  fr: {
    auth: AUTH_FR,
    accounts: ACCOUNTS_FR,
    validation: VALIDATION_FR,
    candidate: CANDIDATE_FR,
    organization: ORGANIZATION_FR,
    countries: COUNTRIES_FR,
    languages: LANGUAGES_FR,
    record: RECORD_FR,
    public: PUBLIC_FR,
    common: COMMON_FR,
  },
  en: {
    auth: AUTH_EN,
    accounts: ACCOUNTS_EN,
    validation: VALIDATION_EN,
    candidate: CANDIDATE_EN,
    organization: ORGANIZATION_EN,
    countries: COUNTRIES_EN,
    languages: LANGUAGES_EN,
    record: RECORD_EN,
    public: PUBLIC_EN,
    common: COMMON_EN,
  },
  // NOTE: Add here all the other languages, the object format should match the French one
};

const locales: Record<string, Locale> = {
  en: enGB,
  fr: fr,
};

const namespaces = Object.keys(
  i18nResources.fr,
) as unknown as keyof (typeof i18nResources)["fr"];
void i18n
  .use(LngDetector) // detect user language with i18next-browser-languagedetector (from browser, cookie, localStorage, etc)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: i18nResources,
    detection: {
      // it's to convert detected language to the format used in the app
      // ex : lng detected is "fr-FR" but we want to use "fr"
      convertDetectedLanguage: (lng) => lng.split("-")[0],
    },
    ns: namespaces,
    defaultNS: "auth",
    fallbackLng: localStorage.getItem("i18nextLng") ?? "fr",
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: (value, format, lng) => {
        if (isDate(value)) {
          const locale: Locale = locales[lng ?? "fr"];
          return formatDate(value, format, { locale });
        }
        return value;
      },
    },
  });

setLocale({
  mixed: {
    default: translationWithParams("validation:INVALID_FIELD"),
    required: translationWithParams("validation:REQUIRED"),
  },
  string: {
    email: validationTranslation("validation:email.INVALID_EMAIL"),
  },
});

// NOTE: Translation function to use only with setLocale
function translationWithParams<
  N extends Namespace,
  const TOpt extends TOptions,
  const TKey extends ParseKeys<N, TOpt, KPrefix>,
  KPrefix extends KeyPrefix<FallbackNs<N>> = undefined,
>(key: TKey, namespace: Namespace = ["validation"]) {
  return (values: Record<string, unknown>) =>
    validationTranslation<N, TOpt, TKey, KPrefix>(key, values, namespace);
}

export function validationTranslation<
  N extends Namespace,
  const TOpt extends TOptions,
  const TKey extends ParseKeys<N, TOpt, KPrefix>,
  KPrefix extends KeyPrefix<FallbackNs<N>> = undefined,
>(
  key: TKey,
  values: Record<string, unknown> = {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _namespace: Namespace = ["validation"],
): string {
  return i18n.t(key as string, values);
}

export default i18n;
