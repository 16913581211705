import { Profile } from "../profile/profileModel";
import { toastsWithIntl } from "../toastService";
import {
  archiveSharedProfileRequest,
  unarchiveSharedProfileRequest,
  updateOrganizationRequest,
} from "./organizationApi";
import { NewOrganizationData, Organization } from "./organizationModel";

export interface OrganizationService {
  updateOrganizationById: (
    organizationId: Organization["id"],
    data: NewOrganizationData,
  ) => Promise<Organization>;
  archiveSharedProfile: (
    profileUid: Profile["uid"],
    organizationId: Organization["id"],
  ) => Promise<void>;
  unarchiveSharedProfile: (
    profileUid: Profile["uid"],
    organizationId: Organization["id"],
  ) => Promise<void>;
}

export const organizationService = () => {
  const { toastSuccess, toastError } = toastsWithIntl(["organization"]);

  const updateOrganization: OrganizationService["updateOrganizationById"] = (
    organizationId,
    data,
  ): Promise<Organization> => {
    return updateOrganizationRequest(organizationId, data).then(
      (updatedOrganization) => {
        toastSuccess("organization:update.SUCCESS");
        return Promise.resolve(updatedOrganization);
      },
      (err) => {
        toastError("organization:update.ERROR");
        return Promise.reject(err);
      },
    );
  };

  const archiveSharedProfile: OrganizationService["archiveSharedProfile"] = (
    profileUid,
    organizationId,
  ) => {
    return archiveSharedProfileRequest(profileUid, organizationId).then(
      () => {
        toastSuccess("organization:shared-profile.archive.SUCCESS");
      },
      () => {
        toastError("organization:shared-profile.archive.ERROR");
      },
    );
  };

  const unarchiveSharedProfile: OrganizationService["unarchiveSharedProfile"] =
    (profileUid, organizationId) => {
      return unarchiveSharedProfileRequest(profileUid, organizationId).then(
        () => {
          toastSuccess("organization:shared-profile.unarchive.SUCCESS");
        },
        () => {
          toastError("organization:shared-profile.unarchive.ERROR");
        },
      );
    };

  return {
    updateOrganization,
    archiveSharedProfile,
    unarchiveSharedProfile,
  };
};
