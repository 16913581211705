import { RouteObject } from "react-router-dom";
import { authService } from "../services/auth/authService";
import { redirectOnCondition } from "./routerHelpers";
import PrivateArea from "../views/PrivateArea";
import { candidatesRoutes } from "./candidateRoutes";
import AccountPage from "../views/account/AccountPage";
import { userService } from "../services/user/userService";
import { organizationRoutes } from "./organizationRoutes";

const { isLoggedIn } = authService();
const { getLoggedUser } = userService();

export const privateRoutes: RouteObject = {
  path: "",
  element: <PrivateArea />,
  async loader() {
    return {
      loggedUser: isLoggedIn() ? await getLoggedUser() : null,
    };
  },
  children: redirectOnCondition(
    [
      {
        path: "admin",
        children: [
          {
            index: true,
            // eslint-disable-next-line i18next/no-literal-string
            element: <div>Logged In!</div>,
          },
        ],
      },
      {
        path: "candidate",
        children: [candidatesRoutes],
      },
      {
        path: "account",
        element: <AccountPage />,
        async loader() {
          return {
            loggedUser: await getLoggedUser(),
          };
        },
      },
      {
        path: "organization",
        children: [organizationRoutes],
      },
    ],
    () => !isLoggedIn(),
    "/login",
  ),
};
