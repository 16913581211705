import { Link } from "react-router-dom";
import ResetPasswordForm from "./ResetPasswordForm";
import { useTranslation } from "react-i18next";

function ResetPasswordPage() {
  const { t } = useTranslation();
  return (
    <>
      <div className="auth-screen">
        <div className="auth-card">
          <div className="side-image"></div>
          <div className="card_body">
            <ResetPasswordForm />
            <Link to="/login">{t("auth:reset-password.BACK_TO_LOGIN")}</Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPasswordPage;
