import { useLoaderData, useNavigate } from "react-router-dom";
import { Profile } from "../../services/profile/profileModel";
import { Fragment, useMemo, useState } from "react";
import Dialog from "../../components/Dialog";
import { createProfileRequest } from "../../services/profile/profileApi";
import ProfileCard from "./profile/ProfileCardComponent";
import { compareBoolean, orderByField } from "../../react-helpers/array";
import { User } from "../../services/user/userModel";
import { useTranslation } from "react-i18next";
import ProfileForm from "./profile/ProfileForm";
import ImgCandidateGuide from "../../assets/img/guide-candidats.png";

const AddProfileBtn = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <button className="card-new" onClick={onClick}>
      <div className="card_image_container">
        <canvas className="card_image_bg" width="16" height="9" />
      </div>

      <div className="card_body">
        {t("candidate:dashboard.CREATE_PROFILE_BUTTON")}
      </div>
    </button>
  );
};

const CandidateDashboardPage = () => {
  const { t } = useTranslation();
  const { profiles, loggedUser } = useLoaderData() as {
    profiles: Profile[];
    loggedUser: User;
  };
  const navigate = useNavigate();

  const [showCreateProfile, setShowCreateProfile] = useState(false);

  const sortedProfiles = useMemo(
    () => profiles.sort(orderByField("featured", true, compareBoolean)),
    [profiles],
  );

  const profilesSlots = useMemo(
    () => [...Array(4)].map((_, idx) => sortedProfiles[idx] ?? null),
    [sortedProfiles],
  );

  const hasSubscription = useMemo(() => {
    return (
      new Date(loggedUser.subscriptionEndDate) > new Date() &&
      loggedUser.subscriptionStartDate !== null
    );
  }, [loggedUser]);

  return (
    <div className="layout_content --bg--candidate">
      <div className="section --bg--candidate">
        <div className="container --wrap">
          <h2 className="section_title">
            {t("candidate:dashboard.MAIN_PROFILE")}
          </h2>
          {profilesSlots[0] && (
            <p className="body">
              {t("candidate:dashboard.CREATED_AT", {
                date: new Date(profilesSlots[0].createdAt),
              })}
            </p>
          )}

          <div className="lblock grid">
            <div>
              {profilesSlots[0] ? (
                <ProfileCard profile={profilesSlots[0]} />
              ) : (
                <AddProfileBtn onClick={() => setShowCreateProfile(true)} />
              )}
            </div>
            <div className="lrow --center">
              <a
                className="dashboard-candidate-guide-cta"
                href={t("candidate:CANDIDATE_GUIDE_LINK")}
                target="_blank"
                rel="noreferrer"
              >
                <img src={ImgCandidateGuide} alt="guide candidat" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {hasSubscription && (
        <div className="section --bg--premium">
          <div className="container --wrap">
            <h2 className="page_title">
              {t("candidate:dashboard.premium.TITLE")}
            </h2>
            <p className="body">
              {t("candidate:dashboard.premium.DESCRIPTION")}
            </p>
            <div className="lblock grid">
              {profilesSlots.slice(1).map((profile, idx) => (
                <Fragment
                  key={profile ? `profile-${profile.id}` : `add-profile-${idx}`}
                >
                  {profile ? (
                    <ProfileCard profile={profile} />
                  ) : (
                    <AddProfileBtn onClick={() => setShowCreateProfile(true)} />
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      )}

      {showCreateProfile && (
        <Dialog
          className="--branded"
          onClose={() => setShowCreateProfile(false)}
        >
          <div>
            <div className="popup_head">
              <h2 className="popup_title">
                {t("candidate:create-profile.DIALOG_TITLE")}
              </h2>
            </div>

            <ProfileForm
              onSubmit={(values) => {
                return createProfileRequest(values).then((profile) => {
                  return navigate(`/candidate/profiles/${profile.id}`);
                });
              }}
              onCancel={() => setShowCreateProfile(false)}
            />
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default CandidateDashboardPage;
