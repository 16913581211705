import { Organization } from "../organization/organizationModel";
import organization from "../../internationalization/organization/en.json";
import candidate from "../../internationalization/candidate/en.json";
import { t } from "i18next";

export interface User {
  id: number;
  email: string;
  password?: string | null;
  firstname: string;
  lastname: string;
  admin: boolean | null;
  validEmail: boolean;
  adminOrga: boolean;
  phoneNumber?: string | null;
  positionInOrganization?: string | null;
  organizationId: Organization["id"];
  organization: Organization;
  subscriptionStartDate: string;
  subscriptionEndDate: string;
}

export interface NewUserData {
  email: string;
  firstname: string;
  lastname: string;
  password: string;
  phoneNumber: string | null;
  positionInOrganization: string | null;
  searchedPost: string | null;
}

export interface UserToUpdate {
  firstname: string;
  lastname: string;
}

export const POSITIONS = Object.entries(organization.positions).map(
  ([k]) => [k, t(`organization:positions.${k}`)] as [string, string],
);

export const POSTS = Object.entries(candidate.posts).map(
  ([k]) => [k, t(`candidate:posts.${k}`)] as [string, string],
);
