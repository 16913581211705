import { Dispatch, SetStateAction, useCallback, useState } from "react";
import RecorderConfigurationComponent, {
  InterlocutorChoiceComponent,
} from "./RecorderConfigurationComponent";
import { VideoPreview, useRecorder } from "../../services/recorderService";
import { Profile } from "../../services/profile/profileModel";
import { randomUUID } from "../../react-helpers/crypto";
import Draggable from "react-draggable";
import IconRecord from "../../assets/icons/icon-record.svg";
import IconStop from "../../assets/icons/icon-stop.svg";
import {
  MAX_RECORD_LENGTH_SEC,
  PRE_TIMER_DURATION_SEC,
  RecordType,
  RecorderConfig,
} from "./RecordPage";
import { ReturnButton } from "../../components/ReturnButton";
import SubmitButton from "../../components/SubmitButton";
import Dialog from "../../components/Dialog";
import { Capsule } from "../../services/capsule/capsuleModel";
import { cx } from "../../react-helpers/css";
import { useTranslation } from "react-i18next";

const RecorderComponent = ({
  type,
  capsule,
  profile,
  config,
  setConfig,
  setVideos,
  setShowReview,
}: {
  type: RecordType;
  capsule?: Capsule;
  profile?: Profile;
  config: RecorderConfig;
  setConfig: Dispatch<SetStateAction<RecorderConfig>>;
  setVideos(
    videos: [string, Blob][] | ((prev: [string, Blob][]) => [string, Blob][]),
  ): void;
  setShowReview(value: boolean): void;
}) => {
  const { t } = useTranslation();
  const { isRecorderReady, timeElapsed, recording, onStart, onStop } =
    useRecorder()!;

  const [timeout, setMaxLengthTimeout] = useState<number | null>(null);
  const [timer, setTimer] = useState<null | number>(null);
  const [showConfiguration, setShowConfiguration] = useState(
    !config.initialized,
  );
  const [showInterlocutorChoice, setShowInterlocutorChoice] = useState(false);
  const [showSettingsMenu, setShowSettingsMenu] = useState(false);

  const stop = useCallback(async () => {
    const prevVideo = await onStop();
    timeout && clearTimeout(timeout);
    setMaxLengthTimeout(null);

    setVideos((v) => [...v, [randomUUID(), prevVideo]]);
    setShowReview(true);
  }, [onStop, setShowReview, setVideos, timeout]);

  const start = useCallback(async () => {
    setTimer(null);
    const interval = await new Promise<number>((resolve) => {
      const interval = setInterval(() => {
        setTimer((p) => {
          if (p === null) return PRE_TIMER_DURATION_SEC;
          else if (p > 1) return p - 1;
          else {
            resolve(interval);
            return 0;
          }
        });
      }, 1000);
    });

    clearInterval(interval);

    onStart();
    setTimer(0);
    setMaxLengthTimeout(setTimeout(() => stop(), MAX_RECORD_LENGTH_SEC * 1000));
  }, [onStart, stop]);

  return (
    <div
      className={cx([
        "layout_content",
        type === "profile" && "--bg--candidate",
        type === "capsule" && "--bg--organization",
      ])}
    >
      <div className="page-content container --wrap--m">
        <div>
          <ReturnButton
            fallbackUrl={
              type === "profile"
                ? `/candidate/profiles/${profile!.id}`
                : type === "capsule"
                  ? `/organization/capsules/${capsule!.id}`
                  : undefined
            }
          >
            {t("record:record.GO_BACK_BUTTON")}
          </ReturnButton>
        </div>

        <div className="lblock--l video-container">
          {typeof timer === "number" && !recording && (
            // REVIEW : @cedric impossible to reproduce,
            // improve behaviour by preloading media recorder, set on "pause", and launch when timer is out
            // this is a quick fix to prevent small lagging experience when recorder takes time to laucnh after countdown
            <div className="recorder-timer">{timer}</div>
          )}
          {config.interlocutor && (
            <div className="recorder-avatar">
              <img src={config.interlocutor} />
            </div>
          )}
          {config.showNotes && (
            <Draggable bounds="body" handle=".handle">
              <div className="recorder-notes">
                <div className="handle" />
                <button
                  className="btn--icon --simple --close"
                  onClick={() => setConfig((c) => ({ ...c, showNotes: false }))}
                ></button>
                <textarea
                  className="textarea"
                  value={
                    config.notes.length > 0
                      ? config.notes
                      : t("record:config.TAKE_NOTES")
                  }
                  onChange={(e) =>
                    setConfig((p) => ({ ...p, notes: e.target.value }))
                  }
                />
              </div>
            </Draggable>
          )}

          <div className="recorder-controls">
            <div className="controls_buttons">
              <button
                className="btn--icon --simple --person"
                onClick={() => setShowSettingsMenu(true)}
              />
              <button
                className="btn--icon --simple --notes"
                onClick={() => setShowSettingsMenu(true)}
              />
            </div>

            {showSettingsMenu && (
              <div className="menu">
                <div className="popover-menu-head">
                  <button
                    className="btn--icon --simple --close"
                    onClick={() => setShowSettingsMenu(false)}
                  />
                </div>

                <button
                  className="link --s"
                  onClick={() => {
                    setShowInterlocutorChoice(true);
                    setShowSettingsMenu(false);
                  }}
                >
                  {config.interlocutor
                    ? t("record:config.CHANGE_INTERLOCUTOR")
                    : t("record:config.CHOOSE_INTERLOCUTOR")}
                </button>
                <button
                  className="link --s"
                  onClick={() => {
                    setConfig((c) => ({ ...c, showNotes: !c.showNotes }));
                    setShowSettingsMenu(false);
                  }}
                >
                  {t("record:record.SHOW_NOTES_BUTTON", {
                    action: config.showNotes
                      ? t("record:record.actions.HIDE")
                      : t("record:record.actions.SHOW"),
                  })}
                </button>
              </div>
            )}

            {/* <div className="recorder-elapsed">
              {timeElapsed && <ElapsedComponent timeElapsed={timeElapsed} />}
            </div> */}
          </div>

          <VideoPreview />
          {!recording && timer === null && (
            <div className="start-recording">
              <SubmitButton onClick={start} disabled={!isRecorderReady}>
                <img src={IconRecord} alt="record" className="icon" />
                {t("record:record.recording-button.START_RECORDING")}
              </SubmitButton>
            </div>
          )}
          <div className="progress">
            <div
              className="bar"
              style={{ width: ((120 - (timeElapsed ?? 0)) / 120) * 100 + "%" }}
            ></div>
          </div>
          {recording && (
            <SubmitButton className="btn--stop-recording" onClick={stop}>
              <img src={IconStop} className="icon" />
              <span className="--hide--xs">
                {t("record:record.recording-button.STOP_RECORDING")}
              </span>
            </SubmitButton>
          )}
        </div>

        {showInterlocutorChoice && (
          <Dialog
            className="--branded"
            onClose={() => setShowInterlocutorChoice(false)}
          >
            <InterlocutorChoiceComponent
              onChoice={(interlocutor) => {
                setConfig((p) => ({ ...p, interlocutor }));
                setShowInterlocutorChoice(false);
              }}
            />
          </Dialog>
        )}
        {showConfiguration && (
          <RecorderConfigurationComponent
            type={type}
            capsule={capsule}
            profile={profile}
            onClose={(values) => {
              setConfig(values);
              setShowConfiguration(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default RecorderComponent;
