import { format, Locale, parseISO } from "date-fns";

export function formatDate(
  date: Date | string,
  dateFormat = "P",
  options?: { locale: Locale },
): string {
  return format(
    typeof date === "string" ? parseISO(date) : date,
    dateFormat,
    options,
  );
}
