import { NavLink, useSearchParams } from "react-router-dom";
import LoginForm from "./LoginForm";
import { useEffect } from "react";
import { authService } from "../../../services/auth/authService";
import Logo from "../../../assets/logo-2MAX-large.png";
import CommonFooter from "../../../components/CommonFooter";
import { useKeepQueryParams } from "../../../router/routerHelpers";
import { t } from "i18next";

function LoginPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const keepQueryParams = useKeepQueryParams();
  const { validateUser } = authService();

  useEffect(() => {
    const guid = searchParams.get("guid");
    if (guid) {
      void validateUser(guid).then(() => {
        setSearchParams((prev) => {
          prev.delete("guid");
          return prev;
        });
      });
    }
  }, [searchParams, setSearchParams, validateUser]);

  return (
    <>
      <div className="auth-screen">
        <img src={Logo} alt="2 Minutes Max" className="logo" />
        <div className="auth-card">
          <div className="card_body">
            <LoginForm profileShareUid={searchParams.get("uid") ?? undefined} />
            <div className="lblock --txt--center">
              <p className="auth-cta">{t("auth:login.NO_ACCOUNT")}</p>
              <NavLink
                className="link --underlined"
                to={keepQueryParams("/register")}
              >
                {t("login.SIGN_UP")}
              </NavLink>
            </div>
          </div>
        </div>
        <CommonFooter />
      </div>
    </>
  );
}

export default LoginPage;
