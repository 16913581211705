import { useEffect } from "react";
import { createPortal } from "react-dom";
import { cx } from "../react-helpers/css";

export default function Dialog({
  onClose,
  modal,
  children,
  className,
}: {
  onClose?: () => void;
  modal?: boolean;
  children: React.JSX.Element;
  className?: string;
}) {
  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);
  const wrapper = (
    <div className="screen-overlay">
      <div className={cx(["popup", modal && "--modal", className])}>
        {!modal && (
          <div className="popup_close">
            <button className="btn--icon --simple --close" onClick={onClose} />
          </div>
        )}
        {children}
      </div>
    </div>
  );
  return createPortal(wrapper, document.body);
}

// TODO CG/JP : useDialog.showDialog()
