import { RouteObject, redirect, useSearchParams } from "react-router-dom";
import { compile, PathFunction } from "path-to-regexp";
import queryString from "query-string";

export function redirectOnCondition(
  routes: RouteObject[] | RouteObject,
  condition: () => boolean | Promise<boolean>,
  redirectTo: string,
): RouteObject[] {
  const originalRoutes = Array.isArray(routes) ? routes : [routes];

  originalRoutes.forEach((r) => {
    const previousLoader = r.loader;
    r.loader = async (...params) => {
      // NOTE: On error while checking condition, redirect by default
      let needToRedirect;
      try {
        needToRedirect = await Promise.resolve(condition());
      } catch (e) {
        return redirect(redirectTo);
      }

      let querry = "";
      Object.entries(params[0].params).forEach(([key, value], index) => {
        querry += `${index > 0 ? "&" : ""}${key}=${value}`;
      });

      if (needToRedirect)
        return redirect(querry ? redirectTo + "?" + querry : redirectTo);
      else if (previousLoader) return previousLoader(...params);
      // NOTE: If no loader and condition is false, resolve normally
      else return Promise.resolve(null);
    };

    if (r.children) redirectOnCondition(r.children, condition, redirectTo);
  });

  return originalRoutes;
}

const cache: Record<string, PathFunction<Record<string, unknown>>> = {};
const cacheLimit = 10000;
let cacheCount = 0;

function compilePath(path: string): PathFunction<Record<string, unknown>> {
  if (cache[path]) return cache[path];

  const generator = compile(path);

  if (cacheCount < cacheLimit) {
    cache[path] = generator;
    cacheCount++;
  }

  return generator;
}

export function generatePath(
  uncleanedPath = "/",
  params = {},
  queryParams: Record<string, unknown> | undefined = undefined,
): string {
  const path = uncleanedPath.replace(/\/\?.*/, "/");
  const searchParams = queryParams
    ? queryString
        .stringify(
          Object.fromEntries(
            Object.entries(queryParams).map(([key, value]) => [
              key,
              typeof value === "boolean" ? (value ? null : undefined) : value,
            ]),
          ),
        )
        .replace("%20", "+")
    : ""; // NOTE: Compatibility between react-router-dom and query-string

  if (path === "/") {
    return searchParams.length > 0 ? `/?${searchParams}` : "/";
  } else {
    const compiledPath = compilePath(path)(params);
    if (queryParams === undefined || Object.keys(queryParams).length < 0)
      return compiledPath;

    // NOTE: Need a trailing slash with query params for react-router-dom
    // Else all the Link components will make the application crash
    return compiledPath.replace(/\/?$/, `/?${searchParams}`);
  }
}

export function useKeepQueryParams() {
  const [queryParams] = useSearchParams();

  return (path: string) => {
    return generatePath(path, {}, Object.fromEntries(queryParams.entries()));
  };
}
