import { compareAsc } from "date-fns";
import { useMemo, useState } from "react";

export function orderByField<P, K extends keyof P>(
  field: K,
  desc?: boolean,
  comparisonMethod = (a: P[K], b: P[K]): number => (a > b ? -1 : 1),
): (a: P, b: P) => number {
  return desc
    ? function (a: P, b: P) {
        return comparisonMethod(a[field], b[field]);
      }
    : function (a: P, b: P) {
        return comparisonMethod(b[field], a[field]);
      };
}

export function deepSearch<E>(element: E, word: string): boolean {
  if (!element) return false;
  if (typeof element === "string") {
    return element.toLowerCase().indexOf(word) >= 0;
  } else if (typeof element === "number") {
    return (
      element.toString(10).indexOf(word) >= 0 || parseFloat(word) === element
    );
  } else if (Array.isArray(element)) {
    return element.some((subElement) => deepSearch(subElement, word));
  } else if (typeof element === "object") {
    const properties = Object.getOwnPropertyNames(element) as (keyof E)[];
    if (properties.some((val) => deepSearch(element[val], word))) return true;
  }
  return false;
}

export function searchGenerator<E>(
  expression: string,
  mapper?: (element: E) => unknown,
): (element: E) => boolean {
  if (expression.length === 0) return () => true;
  const words = expression.split(" ").map((e) => e.toLowerCase());

  return mapper
    ? (element: E) => {
        const mappedElement = mapper(element);
        return words.every((w) => deepSearch(mappedElement, w));
      }
    : (element: E) => words.every((w) => deepSearch(element, w));
}

export function useSearch<E>(
  baseList: E[],
  mapper?: (element: E) => unknown,
): [E[], (search: string) => void] {
  const [search, setSearch] = useState<string>("");

  const filter = useMemo(
    () => searchGenerator(search, mapper),
    [search, mapper],
  );

  const newList = useMemo(() => baseList.filter(filter), [baseList, filter]);

  return [newList, setSearch];
}

export function spliceReturn<T>(
  array: T[],
  index: number | ((e: T) => boolean),
): T[] {
  const newArray = array.slice(0);
  newArray.splice(
    typeof index === "number" ? index : newArray.findIndex(index),
    1,
  );
  return newArray;
}

export function replaceInArray<T>(
  array: T[],
  index: number | ((e: T) => boolean),
  newObject: T,
): T[] {
  const newArray = array.slice(0);
  newArray[typeof index === "number" ? index : newArray.findIndex(index)] =
    newObject;
  return newArray;
}

export function upsertElementInArray<T>(
  array: T[],
  find: number | ((element: T) => boolean),
  upsert: (prev?: T) => T,
): T[] {
  const index = typeof find === "number" ? find : array.findIndex(find);
  if (index === -1) return [...array, upsert()];
  return replaceInArray(array, index, upsert(array[index]));
}

export function compareBoolean(boolA: boolean, boolB: boolean): number {
  return boolA === boolB ? 0 : boolA ? -1 : 1;
}

export function compareString(stringA: string, stringB: string): number {
  return stringA.localeCompare(stringB);
}

export function compareStringNullable(
  stringA: string | null,
  stringB: string | null,
  nullAtTheEnd = true,
): number {
  if (stringA === null && stringB === null) return 0;
  if (stringA === null) return nullAtTheEnd ? 1 : -1;
  if (stringB === null) return nullAtTheEnd ? -1 : 1;

  return compareString(stringA, stringB);
}

export function compareDate(dateA: Date, dateB: Date): number {
  return compareAsc(dateA, dateB);
}

export function groupByField<
  Arr extends any[],
  T extends Arr extends (infer T)[] ? T : never,
  Key extends keyof T & (string | number | symbol),
  TKey extends T[Key] & (string | number | symbol),
>(array: Arr, key: Key | ((el: T) => TKey)): Record<TKey, Arr> {
  return array.reduce(
    (result, item) => {
      const k = typeof key === "function" ? key(item) : (item as T)[key];
      const group = (result as T)[k] || [];
      (group as T[]).push(item);
      (result as T)[k] = group;
      return result;
    },
    {} as Record<TKey, Arr>,
  );
}

export function multiSorts<T>(...sorts: ((a: T, b: T) => number)[]) {
  return (a: T, b: T) =>
    sorts.reduce((result, sort) => result || sort(a, b), 0);
}
