export function thumbnailOfVideo(
  video: HTMLVideoElement | undefined | null,
  thumbnailSize = 480,
  failsafeCount = 10,
): Promise<string> {
  return new Promise((resolve, reject) => {
    if (video === undefined || video === null)
      return reject("failed to create thumbnail");

    void video?.play();

    let metadataLoaded = false;
    let dataLoaded = false;
    let suspended = false;

    function check() {
      if (
        video &&
        (video.seekable.length > 0 ||
          (metadataLoaded && dataLoaded && suspended))
      ) {
        metadataLoaded = true;
        dataLoaded = true;
        suspended = true;

        const canvas = document.createElement("canvas");

        // Keep aspect ratio, just scale the preview down
        canvas.width = thumbnailSize;
        canvas.height = thumbnailSize * (video.videoHeight / video.videoWidth);

        // To not loop indefinitely
        let failsafe = failsafeCount;
        // Write the frames to the canvas (fix because some mobile browser doesn't initialize the first frame)
        const interval = setInterval(() => {
          failsafe--;

          if (video !== undefined && video !== null) {
            // Go 10ms each time starting at 0 and finishing at 10ms * failsafeCount
            video.currentTime = 10 * (failsafe - failsafeCount);

            // Draw the video frame in the canvas
            canvas
              .getContext("2d")
              ?.drawImage(video, 0, 0, canvas.width, canvas.height);

            const pixel = canvas
              .getContext("2d")
              ?.getImageData(10, 10, 1, 1).data;

            if (pixel && pixel[3] !== 0) {
              // Check if the pixel is not transparent

              video.pause();
              canvas.remove();
              clearInterval(interval);
              return resolve(canvas.toDataURL("image/jpg", 85));
            }
          }

          if (failsafe < 0) {
            // Should never happen if we can draw the video to the canvas

            // eslint-disable-next-line no-console
            console.warn("Failed to create thumbnail");

            video.pause();
            canvas.remove();
            clearInterval(interval);
            return resolve(canvas.toDataURL("image/jpg", 85));
          }
        }, 100);
      }
    }

    // Wait for metadata of the video
    video?.addEventListener("loadedmetadata", function () {
      if (metadataLoaded) return;
      metadataLoaded = true;
      check();
    });
    // Wait for the data of the first frames
    video?.addEventListener("loadeddata", function () {
      if (dataLoaded) return;
      dataLoaded = true;
      check();
    });
    // Wait for the video to be suspended (first frames finished loading the video is set to suspend)
    video?.addEventListener("suspend", function () {
      if (suspended) return;
      suspended = true;
      check();
    });

    check();
  });
}

export async function thumbnailOfBlobVideo(
  blob: Blob,
  thumbnailSize = 480,
  failsafeCount = 10,
): Promise<string> {
  const video = document.createElement("video");
  video.muted = true;
  video.autoplay = true;
  video.playsInline = true;
  const video_url = URL.createObjectURL(blob);
  video.src = video_url;

  return thumbnailOfVideo(video, thumbnailSize, failsafeCount).then(
    (res) => {
      // Clean up the video in memory
      URL.revokeObjectURL(video_url);
      video.remove();
      return res;
    },
    (err) => Promise.reject(err),
  );
}
