import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Dialog from "./Dialog.tsx";
import { Namespace, ParseKeys, TOptions } from "i18next";
import { KeyPrefix } from "i18next";
import { FallbackNs, useTranslation } from "react-i18next";
import { spliceReturn } from "../react-helpers/array";

interface ConfirmOptions {
  closable?: boolean;
}

interface Props {
  content: ReactElement | ReactNode;
  options: ConfirmOptions;
  onCancel?: (() => void) | boolean;
  onConfirm?: (() => void) | boolean;
}

function ConfirmationDialog({ content, options, onCancel, onConfirm }: Props) {
  const { t } = useTranslation();
  const { closable } = options;

  return (
    <Dialog
      className="--branded"
      onClose={closable && typeof onCancel === "function" ? onCancel : () => []}
    >
      <div>
        <div className="popup_head">
          <h2 className="popup_title">{t("common:confirm-dialog.TITLE")}</h2>
        </div>
        <div className="popup_body">{content}</div>

        <div className="popup_footer">
          {onCancel && (
            <button
              className={"btn--grey"}
              onClick={typeof onCancel === "function" ? onCancel : undefined}
            >
              {t("common:confirm-dialog.buttons.CANCEL")}
            </button>
          )}
          <button
            className={"btn--1"}
            onClick={typeof onConfirm === "function" ? onConfirm : undefined}
          >
            {t("common:confirm-dialog.buttons.CONFIRM")}
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export interface ConfirmationAPI {
  contents: [
    ReactElement | ReactNode,
    ConfirmOptions,
    [
      (() => Promise<unknown>) | undefined | boolean,
      (() => Promise<unknown>) | undefined | boolean,
    ],
  ][];
  confirm(
    content: ReactElement | ReactNode,
    confirm?: (() => Promise<unknown>) | boolean,
    cancel?: (() => Promise<unknown>) | boolean,
    options?: ConfirmOptions,
  ): void;
}

export interface ConfirmationAPIWithIntl<
  N extends Namespace,
  TOpt extends TOptions,
  TKey extends ParseKeys<N, TOpt, KPrefix>,
  KPrefix extends KeyPrefix<FallbackNs<N>> = undefined,
> {
  contents: [
    ReactElement | ReactNode,
    ConfirmOptions,
    [
      (() => Promise<unknown>) | undefined | boolean,
      (() => Promise<unknown>) | undefined | boolean,
    ],
  ][];
  confirm<TInterpolationMap extends Record<string, unknown>>(
    key: TKey,
    confirm?: (() => Promise<unknown>) | boolean,
    cancel?: (() => Promise<unknown>) | boolean,
    options?: (TOptions<TInterpolationMap> & ConfirmOptions) | string,
    defaultValue?: string,
  ): void;
}

export interface ProviderConfirmationAPI extends ConfirmationAPI {
  removeContent(content: Props["content"]): void;
}

const ConfirmationContext = createContext<ProviderConfirmationAPI | null>(null);

function useProvideConfirmation(): ProviderConfirmationAPI {
  const [contents, setContents] = useState<ConfirmationAPI["contents"]>([]);

  return {
    contents,
    confirm(content, confirm, cancel, options = { closable: true }) {
      setContents((prevContents) => [
        ...prevContents,
        [content, options, [confirm, cancel]],
      ]);
    },
    removeContent(content) {
      setContents((prevContents) =>
        spliceReturn(
          prevContents,
          prevContents.findIndex((c) => c[0] === content),
        ),
      );
    },
  };
}

// eslint-disable-next-line react-refresh/only-export-components
export function useConfirmationWithIntl<
  N extends Namespace,
  TOpt extends TOptions,
  TKey extends ParseKeys<N, TOpt, KPrefix>,
  KPrefix extends KeyPrefix<FallbackNs<N>> = undefined,
>(ns?: N): ConfirmationAPIWithIntl<N, TOpt, TKey, KPrefix> {
  const [currentContents, setCurrentContents] = useState<Props["content"][]>(
    [],
  );
  const context = useContext(ConfirmationContext)!;
  const { t } = useTranslation(ns);

  useEffect(() => {
    return () => {
      currentContents.forEach((c) => {
        context.removeContent(c);
      });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return useMemo(
    () => ({
      ...context,
      confirm(key, confirm, cancel, options, defaultValue) {
        const content = defaultValue
          ? (t as any)(key, defaultValue, options)
          : (t as any)(key, options);
        setCurrentContents((prevContents) => [...prevContents, content]);

        return context.confirm(
          content,
          confirm && typeof confirm === "function"
            ? () =>
                confirm().then(
                  () => context.removeContent(content),
                  () => {
                    /*Do nothing*/
                  },
                )
            : confirm === true
              ? () => {
                  context.removeContent(content);
                  return Promise.resolve();
                }
              : confirm,
          cancel && typeof cancel === "function"
            ? () =>
                cancel().then(
                  () => context.removeContent(content),
                  () => {
                    /*Do nothing*/
                  },
                )
            : cancel === true
              ? () => {
                  context.removeContent(content);
                  return Promise.resolve();
                }
              : cancel,
          typeof options === "object" ? options : undefined,
        );
      },
    }),
    [context, t],
  );
}

export function ProvideConfirmation({
  children,
}: {
  children: React.ReactNode;
}) {
  const confirmationAPI = useProvideConfirmation();

  return (
    <ConfirmationContext.Provider value={confirmationAPI}>
      {children}

      {confirmationAPI.contents.map((c, index) => (
        <ConfirmationDialog
          key={index}
          content={c[0]}
          onCancel={c[2][1]}
          onConfirm={c[2][0]}
          options={c[1]}
        />
      ))}
    </ConfirmationContext.Provider>
  );
}
