import baseAPI from "../api";
import { Capsule } from "./capsuleModel";

export function getCapsuleRequest(capsuleId: Capsule["id"]): Promise<Capsule> {
  return baseAPI.get(`/capsules/${capsuleId}`).then(({ data }) => data);
}

export function getAllCapsulesRequest(): Promise<Capsule[]> {
  return baseAPI.get("/capsules").then(({ data }) => data);
}

export function getCapsuleByUidRequest(
  capsuleUid: Capsule["uid"],
): Promise<Capsule> {
  return baseAPI.get(`/capsules/uid/${capsuleUid}`).then(({ data }) => data);
}

export function createCapsuleRequest(
  data: Pick<Capsule, "name" | "externalLink" | "lang" | "country">,
): Promise<Capsule> {
  return baseAPI.post("/capsules", data).then(({ data }) => data);
}

export function updateCapsuleRequest(
  id: Capsule["id"],
  data: Partial<Capsule>,
): Promise<Capsule> {
  return baseAPI.put(`/capsules/${id}`, data).then(({ data }) => data);
}

export function deleteCapsuleRequest(id: Capsule["id"]): Promise<void> {
  return baseAPI.delete(`/capsules/${id}`);
}

export function createCapsuleUploadLinkRequest(
  capsuleId: Capsule["id"],
  thumbnail: string,
): Promise<{ uploadLink: string }> {
  return baseAPI
    .post(`/capsules/${capsuleId}/upload`, {
      thumbnail,
    })
    .then(({ data }) => data);
}

export function getCapsuleByOrganizationSlugAndId(
  organizationSlug: string,
  capsuleId: number,
): Promise<Capsule> {
  return baseAPI
    .get(`/capsules/organizations/${organizationSlug}/capsules/${capsuleId}`)
    .then(({ data }) => data);
}

export function archiveCapsuleRequest(capsuleId: Capsule["id"]): Promise<void> {
  return baseAPI.put(`/capsules/${capsuleId}/archive`);
}

export function unarchiveCapsuleRequest(
  capsuleId: Capsule["id"],
): Promise<void> {
  return baseAPI.put(`/capsules/${capsuleId}/unarchive`);
}
