import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useReload from "../hooks/useReload";
import { cx } from "../react-helpers/css";

const LanguageSwitcher = ({ className }: { className?: string }) => {
  const { i18n } = useTranslation();
  const reload = useReload();

  const availableLanguages = useMemo(
    () =>
      Object.keys(i18n.options.resources ?? {}).map((r) =>
        r.toLocaleUpperCase(),
      ),
    [i18n.options.resources],
  );

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) html.lang = i18n.language;
  }, [i18n.language]);

  return (
    <div className="lang-switcher">
      <span className={cx(["icon --globe", className])} />
      <select
        className={cx(["select--simple", className])}
        value={i18n.language.toLocaleUpperCase()}
        onChange={(ev) => {
          void i18n
            .changeLanguage(ev.target.value.toLocaleLowerCase())
            .then(() => reload());
        }}
      >
        {availableLanguages.map((lng) => (
          <option value={lng} key={lng}>
            {lng}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSwitcher;
