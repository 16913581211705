import baseAPI from "../api";
import { User, UserToUpdate } from "./userModel";

export function getUserRequest(userId: User["id"]): Promise<User> {
  return baseAPI.get(`/users/${userId}`).then(({ data }) => data);
}

export function getAllUsersRequest(): Promise<User[]> {
  return baseAPI.get("/users").then(({ data }) => data);
}

export function updateUserRequest(
  userId: User["id"],
  user: UserToUpdate,
): Promise<User> {
  return baseAPI.put(`/users/${userId}`, user).then(({ data }) => {
    return data;
  });
}
