import { NavLink, useNavigate } from "react-router-dom";
import { authService } from "../services/auth/authService";
import Logo from "../assets/logo-2mm-header.png";
import { User } from "../services/user/userModel";
import { useState } from "react";
import { cx } from "../react-helpers/css";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

const CommonHeader = ({ loggedUser }: { loggedUser?: User }) => {
  const { logout } = authService();
  const navigate = useNavigate();
  const [displayMobileMenu, setDisplayMobileMenu] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="header">
      <NavLink to={"/"}>
        <img className="logo" src={Logo} alt="logo" />
      </NavLink>
      <div className="lrow">
        <div className="header_nav">
          <LanguageSwitcher />
          {loggedUser && !loggedUser.organizationId && (
            <a
              className="link --accent"
              href={t("candidate:CANDIDATE_GUIDE_LINK")}
              target="_blank"
              rel="noreferrer"
            >
              {t("common:header.TIPS")}
            </a>
          )}

          {/* TODO better mechanism for responsive menu */}
          {!!loggedUser?.organizationId && (
            <>
              <div className="header_nav --toggle-responsive-menu">
                <button
                  className="btn--icon --menu --simple"
                  onClick={() => setDisplayMobileMenu(!displayMobileMenu)}
                />
              </div>
              <div
                className={cx([
                  "header_nav --responsive",
                  !displayMobileMenu && "--off",
                ])}
                onClick={() => setDisplayMobileMenu(false)}
              >
                <NavLink className="link" to="/organization/candidates">
                  <span>{t("common:header.MY_CANDIDATES")}</span>
                </NavLink>
                <NavLink className="link" to="/organization/capsules">
                  <span>{t("common:header.MY_LISTINGS")}</span>
                </NavLink>
                {loggedUser.organization.validationDate ? (
                  <a
                    className="link"
                    href={t("organization:ORGANIZATION_GUIDE_LINK")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>{t("common:header.RECRUITER_GUIDE")}</span>
                  </a>
                ) : (
                  <>
                    <div
                      className="link --disabled"
                      data-tooltip-id="tile-tooltip"
                      data-tooltip-content={t(
                        "organization:dashboard.tips.TILE",
                      )}
                    >
                      {t("common:header.RECRUITER_GUIDE")}
                    </div>
                    <Tooltip id="tile-tooltip" />
                  </>
                )}
              </div>
            </>
          )}

          {!!loggedUser && (
            <>
              <NavLink className="link" to={"/account"}>
                {t("common:header.MY_ACCOUNT")}
              </NavLink>
              <button
                className="btn--icon --simple --logout"
                onClick={() => {
                  logout().then(
                    () => {
                      navigate("/");
                    },
                    () => {
                      //do nothing
                    },
                  );
                }}
              />
            </>
          )}

          {!loggedUser && (
            <>
              <NavLink className="link" to="/login">
                {t("common:header.LOGIN")}
              </NavLink>
              <NavLink className="link" to="/register">
                {t("common:header.REGISTER")}
              </NavLink>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommonHeader;
