import baseAPI from "../api";
import { Profile } from "../profile/profileModel";
import { NewOrganizationData, Organization } from "./organizationModel";

export const updateOrganizationRequest = (
  organizationId: Organization["id"],
  data: NewOrganizationData,
): Promise<Organization> => {
  return baseAPI
    .put(`/organization/${organizationId}`, data)
    .then(({ data }) => data);
};

export const createOrganizationRequest = (
  data?: NewOrganizationData,
): Promise<Organization> => {
  return baseAPI.post("/organization", data).then(({ data }) => data);
};

export const getOrganizationRequest = (
  organizationId: Organization["id"],
): Promise<Organization> => {
  return baseAPI
    .get(`/organization/${organizationId}`)
    .then(({ data }) => data);
};

export function archiveSharedProfileRequest(
  profileUid: Profile["uid"],
  organizationId: Organization["id"],
): Promise<void> {
  return baseAPI.put(
    `/organization/${organizationId}/share/${profileUid}/archive`,
  );
}

export function unarchiveSharedProfileRequest(
  profileUid: Profile["uid"],
  organizationId: Organization["id"],
): Promise<void> {
  return baseAPI.put(
    `/organization/${organizationId}/share/${profileUid}/unarchive`,
  );
}
