import { Outlet, useLoaderData } from "react-router-dom";
import CommonHeader from "../components/CommonHeader";
import CommonFooter from "../components/CommonFooter";
import { User } from "../services/user/userModel";

const PrivateArea = () => {
  const { loggedUser } = useLoaderData() as { loggedUser?: User };
  return (
    <>
      <CommonHeader loggedUser={loggedUser} />
      <Outlet />
      <CommonFooter loggedUser={loggedUser} />
    </>
  );
};

export default PrivateArea;
