import { object, string as yupString } from "yup";
import SxForm from "../../forms/SxForm";
import SxField from "../../forms/fields/SxField";
import SubmitButton from "../../components/SubmitButton";
import baseAPI from "../../services/api";
import { toastsWithIntl } from "../../services/toastService";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface contactMailContent {
  name: string;
  email: string;
  subject: string;
  message: string;
}

const sendContactMailRequest = (content: contactMailContent): Promise<void> => {
  return baseAPI.post("/contact", content);
};

const sendContactMail = (mailContent: contactMailContent) => {
  const { toastError, toastSuccess } = toastsWithIntl(["common"]);
  return sendContactMailRequest(mailContent).then(
    () => {
      toastSuccess("common:contact.send-contact-mail.SUCCESS");
    },
    () => {
      toastError("common:contact.send-contact-mail.ERROR");
    },
  );
};

const ContactForm = ({
  userMail,
  userName,
}: {
  userMail?: string;
  userName?: string;
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  return (
    <SxForm
      initialValues={{
        email: userMail ?? "",
        name: userName ?? "",
        subject: searchParams.get("object") ?? "",
        message: searchParams.get("message") ?? "",
      }}
      onSubmit={(values) => {
        return sendContactMail(values).then(() => {
          values.subject = "";
          values.message = "";
        });
      }}
      validationSchema={object({
        email: yupString()
          .label(t("common:contact.labels.EMAIL"))
          .email()
          .required(),
        name: yupString().label(t("common:contact.labels.NAME")).required(),
        subject: yupString()
          .label(t("common:contact.labels.SUBJECT"))
          .required(),
        message: yupString()
          .label(t("common:contact.labels.MESSAGE"))
          .required(),
      })}
    >
      <SxField name="name" />
      <SxField name="email" />
      <SxField name="subject" />
      <SxField name="message" as="textarea" />
      <div className="lblock --txt--center">
        <SubmitButton type="submit">{t("common:contact.SUBMIT")}</SubmitButton>
      </div>
    </SxForm>
  );
};

export default ContactForm;
