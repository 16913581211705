import { useLoaderData } from "react-router-dom";
import { useState } from "react";
import ReviewComponent from "./ReviewComponent";
import { Profile } from "../../services/profile/profileModel";
import { ProvideRecorder } from "../../services/recorderService";
import RecorderComponent from "./RecorderComponent";
import { Capsule } from "../../services/capsule/capsuleModel";

export const MAX_RECORD_LENGTH_SEC = 60 * 2;
export const PRE_TIMER_DURATION_SEC = 3;
export const MAX_UNCOMPRESSED_SIZE_MB = 40;

export interface RecorderConfig {
  interlocutor: string | null;
  notes: string;
  showNotes: boolean;
  initialized: boolean;
}

export type RecordType = "profile" | "capsule";

const RecordPage = ({ type }: { type: RecordType }) => {
  const { profile, capsule } = useLoaderData() as {
    profile?: Profile;
    capsule?: Capsule;
  };
  const [showReview, setShowReview] = useState(false);
  const [videos, setVideos] = useState<[string, Blob][]>([]);
  const [config, setConfig] = useState<RecorderConfig>({
    interlocutor: null,
    notes: "",
    showNotes: false,
    initialized: false,
  });

  return (
    <ProvideRecorder
      maxUncompressedSize={MAX_UNCOMPRESSED_SIZE_MB * 1024 * 1024}
    >
      {showReview && (
        <ReviewComponent
          type={type}
          profileId={profile?.id}
          capsuleId={capsule?.id}
          setShowReview={setShowReview}
          videos={videos}
          clearVideos={() => setVideos([])}
        />
      )}
      {!showReview && (
        <RecorderComponent
          type={type}
          profile={profile}
          capsule={capsule}
          setShowReview={setShowReview}
          setVideos={(values) => {
            setVideos(values);
            setShowReview(true);
          }}
          config={config}
          setConfig={setConfig}
        />
      )}
    </ProvideRecorder>
  );
};

export default RecordPage;
