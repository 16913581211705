import baseAPI from "../api";
import { NewOrganizationData } from "../organization/organizationModel";
import { NewUserData, User } from "../user/userModel";
import { LoggedUserData, LoginData } from "./authModel";

export function loginRequest(
  loginData: LoginData,
): Promise<User & LoggedUserData> {
  return baseAPI.post("/auth/login", loginData).then(({ data }) => ({
    ...data.user,
    accessToken: data.accessToken,
  }));
}

export function registerRequest(newUserData: NewUserData): Promise<User> {
  return baseAPI.post("/auth/register", newUserData);
}

export function registerOrganizationRequest(newUserAndOrganization: {
  user: NewUserData;
  organization: NewOrganizationData;
}): Promise<User> {
  return baseAPI
    .post("/auth/register-organization", newUserAndOrganization)
    .then(({ data }) => data);
}

export function lostPasswordRequest(email: User["email"]): Promise<void> {
  return baseAPI.post("/auth/lost-password", { email });
}

export function resetPasswordRequest(
  guid: string,
  newPassword: LoginData["password"],
): Promise<void> {
  return baseAPI.post("/auth/reset-password", { guid, newPassword });
}

export function validateUserRequest(guid: string): Promise<void> {
  return baseAPI.post("/auth/validate/" + guid);
}

export function updatePasswordRequest(
  oldPassword: string,
  newPassword: string,
): Promise<void> {
  return baseAPI.put(`/auth/update-passwd`, { oldPassword, newPassword });
}
