import { useLoaderData } from "react-router-dom";
import { Organization } from "../../services/organization/organizationModel";
import ProfileCard from "../candidate/profile/ProfileCardComponent";

import { useSearch } from "../../react-helpers/array";
import { useEffect, useMemo, useState } from "react";
import { cx } from "../../react-helpers/css";
import { organizationService } from "../../services/organization/organizationService";
import { useConfirmationWithIntl } from "../../components/ConfirmationDialog";
import { useTranslation } from "react-i18next";
import useReload from "../../hooks/useReload";
import { ReturnButton } from "../../components/ReturnButton";

const OrganizationCandidatesProfilesPage = () => {
  const { t } = useTranslation();
  const { organization } = useLoaderData() as { organization: Organization };
  const { archiveSharedProfile, unarchiveSharedProfile } =
    organizationService();

  const { confirm } = useConfirmationWithIntl(["organization"]);
  const reload = useReload();

  const [searchedProfiles, setSearch] = useSearch(
    organization.sharedProfiles,
    (e) => ({
      profile: {
        user: {
          lastname: e.profile.user?.lastname,
          firstname: e.profile.user?.firstname,
        },
      },
    }),
  );

  const sharedProfiles = useMemo(() => {
    return searchedProfiles
      .filter((p) => p.archivedAt === null)
      .sort((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1))
      .sort((a) => {
        return a.profile.disabledDate ? 1 : -1;
      });
  }, [searchedProfiles]);

  const archivedProfiles = useMemo(() => {
    return searchedProfiles
      .filter((p) => p.archivedAt !== null)
      .sort((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1))
      .sort((a) => {
        return a.profile.disabledDate ? 1 : -1;
      });
  }, [searchedProfiles]);

  const [showArchived, setShowArchived] = useState(false);

  useEffect(() => {
    if (showArchived && archivedProfiles.length === 0) {
      setShowArchived(false);
    }
  }, [archivedProfiles.length, showArchived]);

  return (
    <div className="layout_content --bg--organization">
      <div className="page-content container --wrap">
        <div>
          <ReturnButton forcedUrl="/organization">
            {t("organization:RETURN_TO_RECRUITER_DASHBOARD")}
          </ReturnButton>
        </div>

        <div className="page_head lblock">
          <h2 className="section_title">
            {t("organization:candidates-profiles.MY_CANDIDATE_PROFILES")}
          </h2>
        </div>

        {archivedProfiles.length > 0 && (
          <div className="lblock tabs">
            <button
              className={cx(["tab_item", !showArchived && "active"])}
              onClick={() => setShowArchived(false)}
            >
              {t("organization:candidates-profiles.SHARED_PROFILES")}
            </button>
            <button
              className={cx(["tab_item", !!showArchived && "active"])}
              onClick={() => setShowArchived(true)}
            >
              {t("organization:candidates-profiles.ARCHIVED_PROFILES")}
            </button>
          </div>
        )}

        {organization.sharedProfiles.length > 0 && (
          <>
            <div className="lblock--l grid-3">
              <input
                type="text"
                className="input"
                placeholder={t(
                  "organization:candidates-profiles.SEARCH_PROFILE",
                )}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="lblock--l grid-3">
              {!showArchived &&
                sharedProfiles.map((profile) => (
                  <ProfileCard
                    key={profile.profileId}
                    profile={profile.profile}
                    isArchived={!!profile.archivedAt}
                    onArchive={() => {
                      confirm(
                        "organization:shared-profile.archive.CONFIRM",
                        () =>
                          archiveSharedProfile(
                            profile.profile.uid,
                            organization.id,
                          ).then(() => {
                            reload();
                          }),
                        true,
                      );
                    }}
                  />
                ))}
              {!!showArchived &&
                archivedProfiles.map((profile) => (
                  <ProfileCard
                    key={profile.profileId}
                    profile={profile.profile}
                    isArchived={!!profile.archivedAt}
                    onUnarchive={() => {
                      confirm(
                        "organization:shared-profile.unarchive.CONFIRM",
                        () =>
                          unarchiveSharedProfile(
                            profile.profile.uid,
                            organization.id,
                          ).then(() => {
                            reload();
                          }),
                        true,
                      );
                    }}
                  />
                ))}
            </div>
          </>
        )}

        {organization.sharedProfiles.length === 0 && (
          <div className="lblock--l info">
            {t("organization:candidates-profiles.NO_PROFILES_YET")}
            <div>
              {t("organization:candidates-profiles.NO_PROFILES_MESSAGE")}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrganizationCandidatesProfilesPage;
