import { RouteObject } from "react-router-dom";
import { redirectOnCondition } from "./routerHelpers";
import LoginPage from "../views/auth/login/LoginPage";
import LostPasswordPage from "../views/auth/lostPassword/LostPasswordPage";
import ResetPasswordPage from "../views/auth/lostPassword/ResetPasswordPage";
import { authService } from "../services/auth/authService";
import RegisterPage from "../views/auth/register/RegisterPage";

const { isLoggedIn } = authService();

// NOTE: These routes are all the routes that will no longer be accessible once logged in
export const authRoutes: RouteObject = {
  path: "", // public
  children: redirectOnCondition(
    [
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "lost-password",
        element: <LostPasswordPage />,
      },
      {
        path: "reset-password/:guid",
        element: <ResetPasswordPage />,
      },
      {
        path: "register",
        element: <RegisterPage />,
      },
      {
        path: "register/organization",
        element: <RegisterPage />,
      },
    ],
    isLoggedIn,
    "/candidate",
  ),
};
