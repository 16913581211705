import { object, string } from "yup";
import SxForm from "../../../forms/SxForm";
import { Profile } from "../../../services/profile/profileModel";
import { useTranslation } from "react-i18next";
import SxField from "../../../forms/fields/SxField";
import SubmitButton from "../../../components/SubmitButton";
import {
  useCountries,
  useLangs,
} from "../../../services/profile/profileService";

const ProfileForm = ({
  profile,
  onSubmit,
  onCancel,
}: {
  profile?: Profile;
  onSubmit(values: Pick<Profile, "name" | "country" | "lang">): Promise<void>;
  onCancel(): void;
}) => {
  const { t } = useTranslation(["candidate", "validation"]);
  const countries = useCountries();
  const langs = useLangs();

  return (
    <SxForm
      initialValues={{
        name: profile?.name ?? "",
        country: profile?.country ?? "FR",
        lang: profile?.lang ?? "fr",
      }}
      validationSchema={object({
        name: string()
          .label(t("candidate:update-profile.labels.PROFILE_NAME"))
          .required(),
        country: string()
          .label(t("candidate:update-profile.labels.COUNTRY"))
          .oneOf(
            countries.map((c) => c[0]),
            t("validation:profile.INVALID_COUNTRY"),
          )
          .required(),
        lang: string()
          .label(t("candidate:update-profile.labels.LANGUAGE"))
          .oneOf(
            langs.map((c) => c[0]),
            t("validation:profile.INVALID_LANGUAGE"),
          )
          .required(),
      })}
      onSubmit={(values) => {
        return onSubmit(values);
      }}
      disabled={!!profile?.disabledDate}
    >
      <div className="popup_body">
        <SxField name={"name"} />
        <div className="cblock grid-2">
          <div>
            <SxField name={"country"} as={"select"}>
              {countries.map(([code, name]) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </SxField>
          </div>

          <div>
            <SxField name={"lang"} as={"select"}>
              {langs.map(([code, name]) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </SxField>
          </div>
        </div>
      </div>
      <div className="popup_footer">
        <button className="btn--grey" onClick={onCancel}>
          {t("candidate:profile.buttons.CANCEL")}
        </button>
        <SubmitButton>
          {t(
            profile
              ? "candidate:profile.buttons.UPDATE"
              : "candidate:create-profile.buttons.CREATE",
          )}
        </SubmitButton>
      </div>
    </SxForm>
  );
};

export default ProfileForm;
