import {
  MutableRefObject,
  createRef,
  forwardRef,
  useEffect,
  useMemo,
} from "react";

const VideoPlayer = forwardRef<
  HTMLVideoElement,
  {
    video: Blob | null;
  }
>(function VideoPlayer({ video }, ref) {
  const videoRef =
    (ref as MutableRefObject<HTMLVideoElement | null>) ?? createRef();

  useEffect(() => {
    const ref = videoRef.current;

    function handleContextMenu(event: MouseEvent) {
      event.preventDefault();
    }

    ref?.addEventListener("contextmenu", handleContextMenu);

    return () => {
      ref?.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [videoRef]);

  const url = useMemo(
    () => (video ? URL.createObjectURL(video) : null),
    [video],
  );

  return (
    url && (
      <video
        className="videoplayer"
        ref={videoRef}
        controls
        playsInline
        disablePictureInPicture
        disableRemotePlayback
        src={url}
        controlsList="nodownload"
      ></video>
    )
  );
});

export default VideoPlayer;
