import { object, string } from "yup";
import SubmitButton from "../../components/SubmitButton";
import SxForm from "../../forms/SxForm";
import SxField from "../../forms/fields/SxField";
import { authService } from "../../services/auth/authService";
import { useNavigate } from "react-router-dom";
import { validationTranslation } from "../../internationalization/i18n";
import { useTranslation } from "react-i18next";

const PasswordSection = () => {
  const { t } = useTranslation();
  const { updatePassword, logout } = authService();
  const navigate = useNavigate();
  return (
    <div>
      <h2 className="section_title">{t("accounts:password.SECTION_TITLE")}</h2>
      <div className="lblock">
        <SxForm
          initialValues={{
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
          }}
          onSubmit={async (values) => {
            await updatePassword(values.currentPassword, values.newPassword);
            await logout();
            navigate("/login");
          }}
          validationSchema={object({
            currentPassword: string()
              .password()
              .label(t("accounts:password.labels.CURRENT_PASSWORD"))
              .required(),
            newPassword: string()
              .password()
              .label(t("accounts:password.labels.NEW_PASSWORD"))
              .required(),
            confirmNewPassword: string()
              .required()
              .label(t("accounts:password.labels.CONFIRM_PASSWORD"))
              .test(
                "password-match",
                validationTranslation("auth:register.PASSWORDS_MISMATCH"),
                function (value) {
                  return value === this.parent.newPassword;
                },
              ),
          })}
        >
          <SxField name="currentPassword" />
          <SxField name="newPassword" />
          <SxField name="confirmNewPassword" as="password" />
          <div className="lblock--l --txt--center">
            <SubmitButton className="btn--2" type="submit">
              {t("accounts:password.buttons.SAVE")}
            </SubmitButton>
          </div>
        </SxForm>
      </div>
    </div>
  );
};

export default PasswordSection;
