import { object, string } from "yup";
import SxForm from "../../../forms/SxForm";
import { Capsule } from "../../../services/capsule/capsuleModel";
import { useTranslation } from "react-i18next";
import SxField from "../../../forms/fields/SxField";
import SubmitButton from "../../../components/SubmitButton";
import {
  useCountries,
  useLangs,
} from "../../../services/profile/profileService";

const CapsuleForm = ({
  capsule,
  onSubmit,
  onCancel,
}: {
  capsule?: Capsule;
  onSubmit(
    props: Pick<Capsule, "name" | "videoLink" | "lang" | "country">,
  ): Promise<void>;
  onCancel(): void;
}) => {
  const { t } = useTranslation(["organization", "validation"]);
  const countries = useCountries();
  const langs = useLangs();

  return (
    <SxForm
      initialValues={{
        name: capsule?.name ?? "",
        externalLink: capsule?.externalLink ?? "",
        country: capsule?.country ?? "FR",
        lang: capsule?.lang ?? "fr",
      }}
      validationSchema={object({
        name: string()
          .label(t("organization:capsule-form.labels.JOB_TITLE"))
          .required(),
        externalLink: string()
          .label(t("organization:capsule-form.labels.EXTERNAL_LINK"))
          .matches(
            /^(https?:\/\/.*)?$/,
            t("validation:capsule.INVALID_EXTERNAL_LINK"),
          ),
        country: string()
          .label(t("organization:capsule-form.labels.COUNTRY"))
          .oneOf(
            countries.map((c) => c[0]),
            t("validation:capsule.INVALID_COUNTRY"),
          )
          .required(),
        lang: string()
          .label(t("organization:capsule-form.labels.LANGUAGE"))
          .oneOf(
            langs.map((c) => c[0]),
            t("validation:capsule.INVALID_LANGUAGE"),
          )
          .required(),
      })}
      onSubmit={(values) => {
        return onSubmit(values);
      }}
      disabled={!!capsule?.disabledDate}
    >
      <div className="popup_body">
        <SxField name={"name"} />
        <SxField
          name={"externalLink"}
          placeholder={t(
            "organization:capsule-form.placeholders.EXTERNAL_LINK",
          )}
        />
        <div className="cblock grid-2">
          <div>
            <SxField name={"country"} as={"select"}>
              {countries.map(([code, name]) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </SxField>
          </div>

          <div>
            <SxField name={"lang"} as={"select"}>
              {langs.map(([code, name]) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </SxField>
          </div>
        </div>
      </div>
      <div className="popup_footer">
        <button className="btn--grey" onClick={onCancel}>
          {t("organization:capsule-form.CANCEL")}
        </button>
        <SubmitButton>
          {t(
            `organization:capsule-form.${capsule ? "SAVE_CHANGES" : "CREATE_CAPSULE"}`,
          )}
        </SubmitButton>
      </div>
    </SxForm>
  );
};

export default CapsuleForm;
