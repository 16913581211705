import { ErrorResponse, useRouteError } from "react-router-dom";
import { loggerBuilder } from "../services/logger";
import { useTranslation } from "react-i18next";

const logger = loggerBuilder("navigation-error");

function ErrorPage() {
  const { t } = useTranslation();
  const error = useRouteError() as ErrorResponse;
  logger.error(error);

  return (
    <>
      <h1>
        {t("common:error.global.TITLE", {
          statusCode: error.status,
        })}
      </h1>
      <p>{t("common:error.global.MESSAGE")}</p>
    </>
  );
}

export default ErrorPage;
