import axios from "axios";
import { BASE_URL } from "./config";
import loggerBuilder from "./logger";

const logger = loggerBuilder("http-requests");

export const LOGGED_USER_LOCALSTORAGE_ID = "loggedUser";

const baseAPI = axios.create({
  baseURL: BASE_URL,
  xsrfCookieName: "XSRF-TOKEN",
  url: "/",
  headers: {},
  timeout: 10000,
  withCredentials: true,
});

const storedUser = localStorage.getItem(LOGGED_USER_LOCALSTORAGE_ID);
if (storedUser) {
  baseAPI.defaults.headers.Authorization = `Bearer ${
    JSON.parse(storedUser).accessToken
  }`;
}

baseAPI.interceptors.request.use(
  (request) => {
    (request as any).startupTime = Date.now();
    return request;
  },
  (error) => {
    logger.debug(error.config.url, error.data);
    return Promise.reject(error);
  },
);

baseAPI.interceptors.response.use(
  (response) => {
    logger.debug(
      `${response.config.url} executed in ${
        Date.now() - (response.config as any).startupTime
      }ms`,
      response.data,
    );
    return response;
  },
  (error) => {
    logger.debug(error.config.url, error.data);
    return Promise.reject(error);
  },
);

baseAPI.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error?.response?.status === 401) {
      logger.info(
        "User will be disconnected because of a 401 response of the API",
      );
      localStorage.removeItem(LOGGED_USER_LOCALSTORAGE_ID);
      delete baseAPI.defaults.headers.Authorization;

      // setLoggedUserId(null);
      logger.debug("User is disconnected");
    }
    return Promise.reject(error);
  },
);

export default baseAPI;
