import { object, string } from "yup";
import SxForm from "../../../forms/SxForm";
import { validationTranslation } from "../../../internationalization/i18n";
import SxField from "../../../forms/fields/SxField";
import SubmitButton from "../../../components/SubmitButton";
import { authService } from "../../../services/auth/authService";
import { useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { POSITIONS, POSTS } from "../../../services/user/userModel";
import { transformEmptyToUndefined } from "../../../react-helpers/yup";
import { t } from "i18next";

interface InitialValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  searchedPost?: string;
  legalName?: string;
  address?: string;
  positionInOrganization?: string;
  phoneNumber?: string;
}

const RegisterForm = ({
  isOrganization,
  profileShareUid,
}: {
  isOrganization: boolean;
  profileShareUid?: string;
}) => {
  const { register, registerOrganization } = authService();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const initialValues = useMemo(() => {
    let values: InitialValues = {
      firstName: "",
      lastName: "",
      email: searchParams.get("email") ?? "",
      password: "",
      passwordConfirmation: "",
    };
    if (!isOrganization) {
      values = {
        ...values,
        searchedPost: "",
      };
    }
    if (isOrganization) {
      values = {
        ...values,
        legalName: "",
        address: "",
        phoneNumber: "",
        positionInOrganization: "",
      };
    }
    return values;
  }, [isOrganization, searchParams]);

  const validationSchema = useMemo(() => {
    let schema = object({
      firstName: string().label(t("auth:register.labels.FIRSTNAME")).required(),
      lastName: string().label(t("auth:register.labels.LASTNAME")).required(),
      email: string().email().label(t("auth:register.labels.EMAIL")).required(),
      password: string()
        .password()
        .label(t("auth:register.labels.PASSWORD"))
        .required(),
      passwordConfirmation: string()
        .required()
        .label(t("auth:register.labels.PASSWORD_CONFIRM"))
        .test(
          "password-match",
          validationTranslation("auth:register.PASSWORDS_MISMATCH"),
          function (value) {
            return value === this.parent.password;
          },
        ),
    });
    if (!isOrganization) {
      schema = schema.shape({
        searchedPost: string()
          .label(t("auth:register.labels.SEARCHED_POST"))
          .transform(transformEmptyToUndefined)
          .required()
          .oneOf(POSTS.map(([k]) => k)),
      });
    }
    if (isOrganization) {
      schema = schema.shape({
        legalName: string()
          .label(t("auth:register.labels.LEGAL_NAME"))
          .required(),
        positionInOrganization: string()
          .label(t("auth:register.labels.POSITION"))
          .transform(transformEmptyToUndefined)
          .required()
          .oneOf(POSITIONS.map(([k]) => k)),
        phoneNumber: string().label(t("auth:register.labels.PHONE")),
      });
    }
    return schema;
  }, [isOrganization]);

  return (
    <SxForm
      initialValues={initialValues}
      onSubmit={async (values) => {
        let registration: Promise<unknown>;
        if (isOrganization)
          registration = registerOrganization({
            user: {
              firstname: values.firstName,
              lastname: values.lastName,
              email: values.email,
              password: values.password,
              phoneNumber:
                values.phoneNumber && values.phoneNumber.length > 0
                  ? values.phoneNumber
                  : null,
              positionInOrganization: values.positionInOrganization!,
              searchedPost: null,
            },
            organization: {
              legalName: values.legalName!,
            },
            profileShareUid,
          });
        else
          registration = register({
            firstname: values.firstName,
            lastname: values.lastName,
            email: values.email,
            password: values.password,
            phoneNumber: null,
            positionInOrganization: null,
            searchedPost: values.searchedPost!,
          });
        return Promise.resolve(registration).then(
          () => {
            navigate("/login");
          },
          () => {
            // do nothing
          },
        );
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <div className={"info --success"}>
        {isOrganization
          ? t("register.RECRUITER_TIP")
          : t("register.CANDIDATE_TIP")}
      </div>
      <br />
      <SxField
        name="firstName"
        placeholder={t("register.placeholders.FIRSTNAME")}
      />
      <SxField
        name="lastName"
        placeholder={t("register.placeholders.LASTNAME")}
      />
      <SxField name="email" placeholder={t("register.placeholders.EMAIL")} />
      <SxField
        name="password"
        placeholder={t("register.placeholders.PASSWORD")}
      />
      <SxField
        as="password"
        name="passwordConfirmation"
        placeholder={t("register.placeholders.PASSWORD")}
      />
      {isOrganization ? (
        <>
          <SxField
            name="legalName"
            placeholder={t("register.placeholders.LEGAL_NAME")}
          />
          <SxField name="positionInOrganization" as="select">
            <option value="">{t("register.placeholders.POSITION")}</option>
            {POSITIONS.map(([k, v]) => (
              <option key={k} value={k}>
                {v}
              </option>
            ))}
          </SxField>
          <SxField
            name="phoneNumber"
            placeholder={t("register.placeholders.PHONE")}
          />
          <div className="input-tip">{t("register.PHONE_TIP")}</div>
        </>
      ) : (
        <SxField name="searchedPost" as="select">
          <option value="">{t("register.placeholders.SEARCHED_POST")}</option>
          {POSTS.map(([k, v]) => (
            <option key={k} value={k}>
              {v}
            </option>
          ))}
        </SxField>
      )}
      <div className="lblock--l --txt--center">
        <SubmitButton
          type="submit"
          className={isOrganization ? "btn--2" : "btn--1"}
        >
          {t("register.SIGN_UP")}
        </SubmitButton>
      </div>
    </SxForm>
  );
};

export default RegisterForm;
