import LostPasswordForm from "./LostPasswordForm";
import Logo from "../../../assets/logo-2MAX-large.png";
import CommonFooter from "../../../components/CommonFooter";

function LostPasswordPage() {
  return (
    <div className="auth-screen">
      <img src={Logo} alt="2 Minutes Max" className="logo" />
      <div className="auth-card">
        <div className="side-image"></div>
        <div className="card_body">
          <LostPasswordForm />
        </div>
      </div>
      <CommonFooter />
    </div>
  );
}

export default LostPasswordPage;
