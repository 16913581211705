import { useField } from "formik";
import { useState } from "react";
import IconHide from "../../assets/icons/icon-eye-off.svg";
import IconShow from "../../assets/icons/icon-eye.svg";

const SxPassword = ({ showTogglePassword, ...props }: any) => {
  const [field, , helper] = useField(props);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="input-with-btn">
      <input
        {...field}
        {...props}
        onChange={(e) => {
          void helper.setValue(
            e.currentTarget.value === "" ? null : e.currentTarget.value,
          );
        }}
        type={showPassword ? "text" : "password"}
        value={
          field.value === null ?? field.value === undefined ? "" : field.value
        }
      />
      {showTogglePassword && (
        <button type="button" onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? (
            <img alt={"show password"} className={"icon"} src={IconHide} />
          ) : (
            <img alt={"hide password"} className={"icon"} src={IconShow} />
          )}
        </button>
      )}
    </div>
  );
};

export default SxPassword;
