import { createRef, useCallback } from "react";
import Dialog from "../../../components/Dialog";
import SubmitButton from "../../../components/SubmitButton";
import SuspenseVideoPlayer from "../../../components/SuspenseVideoPlayer";
import { Capsule } from "../../../services/capsule/capsuleModel";
import { capsuleService } from "../../../services/capsule/capsuleService";
import { thumbnailOfVideo } from "../../../react-helpers/blob";
import { useTranslation } from "react-i18next";
import useReload from "../../../hooks/useReload";

const CapsuleThumbnailDialog = ({
  onClose,
  capsule,
  video,
}: {
  onClose(): void;
  capsule: Capsule;
  video: Promise<Blob>;
}) => {
  const { t } = useTranslation();
  const reload = useReload();
  const { updateCapsuleById } = capsuleService();

  const ref = createRef<HTMLVideoElement>();

  const updateThumbnail = useCallback(
    async (video: HTMLVideoElement) => {
      const newThumbnail = await thumbnailOfVideo(video);

      return updateCapsuleById(capsule.id, { thumbnail: newThumbnail });
    },
    [capsule.id, updateCapsuleById],
  );

  return (
    <Dialog className="popup--update-thumbnail" onClose={onClose}>
      <>
        <div className="popup_head">
          <h2 className="popup_title">
            {t("organization:thumbnail.DIALOG_TITLE")}
          </h2>
        </div>
        <div className="popup_body">
          <div className="explanations-row">
            <p className="body--40">
              {t("organization:thumbnail.INSTRUCTIONS")}
            </p>
          </div>

          <div className="lblock">
            <SuspenseVideoPlayer
              videoPromise={video}
              ref={ref}
              thumbnail={capsule.thumbnail}
            />
          </div>
        </div>
        <div className="popup_footer">
          <button className="btn--grey" onClick={onClose}>
            {t("organization:thumbnail.CLOSE")}
          </button>
          <SubmitButton
            className="btn"
            onClick={() => {
              return updateThumbnail(ref.current!).then(() => reload());
            }}
          >
            {t("organization:thumbnail.UPDATE")}
          </SubmitButton>
        </div>
      </>
    </Dialog>
  );
};

export default CapsuleThumbnailDialog;
