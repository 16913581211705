import { StringSchema } from "yup";
import {
  LOWER_ALPHA_CHARCODES_LIST,
  NUMS_CHARCODES_LIST,
  UPPER_ALPHA_CHARCODES_LIST,
} from "../../react-helpers/crypto";
import { validationTranslation } from "../../internationalization/i18n";

const DEFAULT_PASSWORD_CONFIG = {
  minLowerCount: 1,
  minUpperCount: 1,
  minNumberCount: 1,
  minSpecials: 1,
  minLength: 8,
  specials: "!@#$%^&*()_-+=[{}]\\|;:\"'<>,.?/",
};

export function passwordValidation(
  this: StringSchema,
  config?: Partial<typeof DEFAULT_PASSWORD_CONFIG>,
) {
  const {
    minLowerCount,
    minUpperCount,
    minNumberCount,
    minSpecials,
    minLength,
    specials,
  } = {
    ...DEFAULT_PASSWORD_CONFIG,
    ...config,
  };
  return this.defined()
    .meta({ password: true })
    .test(
      "password-lower",
      validationTranslation("validation:password.AT_LEAST_LOWERCASES", {
        count: minLowerCount,
      }),
      (value) =>
        [...value].reduce(
          (count, char) =>
            LOWER_ALPHA_CHARCODES_LIST.includes(char.charCodeAt(0))
              ? count + 1
              : count,
          0,
        ) >= minLowerCount,
    )
    .test(
      "password-upper",
      validationTranslation("validation:password.AT_LEAST_UPPERCASES", {
        count: minUpperCount,
      }),
      (value) =>
        [...value].reduce(
          (count, char) =>
            UPPER_ALPHA_CHARCODES_LIST.includes(char.charCodeAt(0))
              ? count + 1
              : count,
          0,
        ) >= minUpperCount,
    )
    .test(
      "password-number",
      validationTranslation("validation:password.AT_LEAST_NUMBERS", {
        count: minNumberCount,
      }),
      (value) =>
        [...value].reduce(
          (count, char) =>
            NUMS_CHARCODES_LIST.includes(char.charCodeAt(0))
              ? count + 1
              : count,
          0,
        ) >= minNumberCount,
    )
    .test(
      "password-specials",
      validationTranslation("validation:password.AT_LEAST_SPECIALS", {
        count: minSpecials,
      }),
      (value) =>
        [...value].reduce(
          (count, char) => (specials.includes(char) ? count + 1 : count),
          0,
        ) >= minSpecials,
    )
    .test(
      "password-length",
      validationTranslation("validation:password.AT_LEAST_CHARACTERS", {
        count: minLength,
      }),
      (value) => value.length >= minLength,
    );
}
