import {
  Form as FormikForm,
  FormikConfig,
  FormikContext,
  FormikValues,
  useFormik,
  FormikHelpers,
} from "formik";
import { createContext } from "react";
import { AnySchema, InferType } from "yup";

interface YupSchemaAPI<Schema extends AnySchema = AnySchema> {
  schema: Schema;
}
export const YupSchemaContext = createContext<YupSchemaAPI | null>(null);

type Props<
  Values,
  Schema extends AnySchema | undefined,
  SchemaValues extends
    | Values
    | NonNullable<AnySchema> = Schema extends AnySchema
    ? InferType<Schema>
    : Values,
> = Omit<FormikConfig<Values>, "validationSchema" | "onSubmit" | "onReset"> & {
  validationSchema?: Schema | (() => Schema);
  onSubmit: (
    values: Values & SchemaValues,
    formikHelpers: FormikHelpers<Values>,
  ) => void | Promise<any>;
  onReset?: (
    values: Values & SchemaValues,
    formikHelpers: FormikHelpers<Values>,
  ) => void;
  disabled?: boolean;
};

function SxForm<
  Values extends FormikValues,
  Schema extends AnySchema | undefined = undefined,
>({ children, disabled, ...props }: Props<Values, Schema>) {
  const schema =
    typeof props.validationSchema === "function"
      ? props.validationSchema()
      : props.validationSchema;

  const formikStateAndHelpers = useFormik({
    ...(props as unknown as FormikConfig<Values>),
    validate: (values) => {
      try {
        if (schema)
          schema.validateSync(values, {
            abortEarly: false,
          });
      } catch (err: any) {
        return err.inner.reduce(
          (obj: any, validationError: any) => ({
            ...obj,
            [validationError.params.path]: (
              obj[validationError.params.path] || []
            ).concat([
              { type: validationError.type, message: validationError.message },
            ]),
          }),
          {},
        );
      }
    },
    onSubmit(values, formikHelpers) {
      const parsedValues = schema ? schema.cast(values) : values;
      return props.onSubmit(parsedValues, formikHelpers);
    },
  });
  return (
    <FormikContext.Provider value={formikStateAndHelpers}>
      <YupSchemaContext.Provider
        value={
          schema
            ? {
                schema,
              }
            : null
        }
      >
        <FormikForm>
          <fieldset disabled={disabled}>
            {typeof children === "function"
              ? children(formikStateAndHelpers)
              : children}
          </fieldset>
        </FormikForm>
      </YupSchemaContext.Provider>
    </FormikContext.Provider>
  );
}

export default SxForm;
