import {
  MutableRefObject,
  createRef,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import Spinner from "../assets/img/spinner.svg";

const SuspenseVideoPlayer = forwardRef<
  HTMLVideoElement,
  {
    videoPromise: Promise<Blob>;
    thumbnail?: string;
  }
>(function SuspenseVideoPlayer({ videoPromise, thumbnail }, ref) {
  const [videoLoaded, setVideoLoaded] = useState(false);

  const videoRef = useMemo(
    () =>
      (ref ??
        createRef<HTMLVideoElement>()) as MutableRefObject<HTMLVideoElement>,
    [ref],
  );

  useEffect(() => {
    if (!videoRef.current?.srcObject)
      void videoPromise.then((m) => {
        if (videoRef.current) videoRef.current.src = URL.createObjectURL(m);
        setVideoLoaded(true);
      });
  }, [videoRef, videoPromise]);

  useEffect(() => {
    const ref = videoRef.current;

    function handleContextMenu(event: MouseEvent) {
      event.preventDefault();
    }

    ref?.addEventListener("contextmenu", handleContextMenu);

    return () => {
      ref?.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [videoRef]);

  return (
    <>
      {!videoLoaded && (
        <div className="videoloader">
          <canvas width="16" height="9" />
          <img className="spinner" src={Spinner} alt="loading" />
        </div>
      )}
      <video
        poster={thumbnail}
        className="videoplayer"
        ref={videoRef}
        controls
        playsInline
        disablePictureInPicture
        disableRemotePlayback
        controlsList="nodownload"
        hidden={!videoLoaded}
      ></video>
    </>
  );
});

export default SuspenseVideoPlayer;
