import baseAPI from "../api";
import { Profile } from "./profileModel";

export function getProfileRequest(profileId: Profile["id"]): Promise<Profile> {
  return baseAPI.get(`/profiles/${profileId}`).then(({ data }) => data);
}

export function getAllProfilesRequest(): Promise<Profile[]> {
  return baseAPI.get("/profiles").then(({ data }) => data);
}

export function getProfilesByUidRequest(
  profileUid: Profile["uid"],
  accessCode?: string,
  email?: string,
): Promise<Profile> {
  return baseAPI
    .get(
      `/profiles/uid/${profileUid}?${[accessCode ? `code=${accessCode}` : null, email ? `email=${encodeURIComponent(email)}` : null].filter((p) => !!p).join("&")}`,
    )
    .then(({ data }) => data);
}

export function validateProfileAccessCodeRequest(
  profileUid: Profile["uid"],
  accessCode: string,
): Promise<void> {
  return baseAPI.get(`/profiles/uid/${profileUid}/validate/${accessCode}`);
}

export function createProfileRequest(
  data: Pick<Profile, "name">,
): Promise<Profile> {
  return baseAPI.post("/profiles", data).then(({ data }) => data);
}

export function updateProfileRequest(
  id: Profile["id"],
  data: Partial<Profile>,
): Promise<Profile> {
  return baseAPI.put(`/profiles/${id}`, data).then(({ data }) => data);
}

export function deleteProfileRequest(id: Profile["id"]): Promise<void> {
  return baseAPI.delete(`/profiles/${id}`);
}

export function createProfileUploadLinkRequest(
  profileId: Profile["id"],
  thumbnail: string,
): Promise<{ uploadLink: string }> {
  return baseAPI
    .post(`/profiles/${profileId}/upload`, {
      thumbnail,
    })
    .then(({ data }) => data);
}

export function generateProfileCoverLetterRequest(
  profileId: Profile["id"],
  qrCode: string,
): Promise<string> {
  return baseAPI
    .post(
      `/profiles/${profileId}/cover-letter`,
      {
        qrCode,
      },
      {
        responseType: "blob",
      },
    )
    .then(({ data }) => data);
}

export function getProfileCoverLetterRequest(
  profileId: Profile["id"],
): Promise<string> {
  return baseAPI
    .get(`/profiles/${profileId}/cover-letter`, { responseType: "blob" })
    .then(({ data }) => data);
}

export function notifyUploadSuccessRequest(
  profileId: Profile["id"],
): Promise<void> {
  return baseAPI.post(`/profiles/${profileId}/notify-upload-success`);
}
