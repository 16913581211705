import { Field, useField } from "formik";
import { SxFieldProps } from "./SxField";
import { ArrayElement } from "../../react-helpers/type";
import { ReactNode } from "react";
import useYupField from "../validation/useYupField";
import { cx } from "../../react-helpers/css";

interface SxCheckboxProps {
  name: SxFieldProps["name"];
  checkbox?: ArrayElement<SxFieldProps["checkboxes"]>;
  isDisabled?: boolean;
  className?: string;
  tip?: string | ReactNode;
  label?: ReactNode;
}

function SxCheckbox({
  name,
  checkbox,
  isDisabled,
  className,
  tip,
  label,
}: SxCheckboxProps) {
  const field = useYupField(name);
  const [, meta] = useField(name);
  return (
    <label
      className={cx([
        "checkbox",
        "field-label",
        className,
        meta.touched && meta.error && "--error",
      ])}
    >
      <Field
        className="checkbox"
        name={name}
        type="checkbox"
        disabled={isDisabled}
        value={checkbox?.value}
      />
      <div>
        {label ?? checkbox?.label ?? field?.spec.label}
        {tip && <div className="field-tip">{tip}</div>}
      </div>
    </label>
  );
}

export default SxCheckbox;
