import { useLoaderData } from "react-router-dom";
import { User } from "../../services/user/userModel";
import IdentitySection from "./IdentitySection";
import PasswordSection from "./PasswordSection";
import OrganizationSection from "./OrganizationSection";
import { ReturnButton } from "../../components/ReturnButton";
import { cx } from "../../react-helpers/css";
import { useTranslation } from "react-i18next";
import SubsciptionSection from "./SubscriptionSection";

// enum AccountSection {
//   Identity = "Identité",
//   Password = "Mot de passe",
//   Organization = "Compte Entreprise",
// }

const AccountPage = () => {
  const { t } = useTranslation();
  const { loggedUser } = useLoaderData() as { loggedUser: User };

  const isOrganization = loggedUser.organizationId !== null;

  return (
    <div
      className={cx([
        "layout_content",
        isOrganization ? "--bg--organization" : "--bg--candidate",
      ])}
    >
      <div className="page-content container --wrap--m">
        <div>
          <ReturnButton>{t("accounts:GO_BACK_BUTTON")}</ReturnButton>
        </div>
        <div className={"grid lblock--l"}>
          <IdentitySection user={loggedUser} />
          <PasswordSection />
          {isOrganization && (
            <div>
              <OrganizationSection organization={loggedUser.organization} />
              <SubsciptionSection />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
