import { PropsWithChildren } from "react";
import { FallbackNs } from "react-i18next";
import {
  AppendKeyPrefix,
  InterpolationMap,
  KeyPrefix,
  Namespace,
  ParseKeys,
  TFunctionReturn,
  TOptions,
  t,
} from "i18next";
import { toast, ToastBar, Toaster, ToastOptions } from "react-hot-toast";

type SimplifiedToastOptions = Pick<
  ToastOptions,
  "duration" | "id" | "position"
>;
interface ToastService {
  toastSuccess(text: string, options?: SimplifiedToastOptions): void;
  toastError(text: string, options?: SimplifiedToastOptions): void;
}

const toastSuccess: ToastService["toastSuccess"] = (title, options) => {
  toast.success(title, {
    ...options,
    duration: options?.duration ?? 5000,
  });
};
const toastError: ToastService["toastError"] = (title, options) => {
  toast.error(title, {
    ...options,
    duration: options?.duration ?? 30000,
  });
};

export const ProvideToasts = ({ children }: PropsWithChildren) => {
  return (
    <>
      <Toaster position="top-right">
        {(t) => (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => toast.dismiss(t.id)}
          >
            <ToastBar toast={t} />
          </div>
        )}
      </Toaster>
      {children}
    </>
  );
};

type ToastServiceWithIntl<
  N extends Namespace,
  KPrefix extends KeyPrefix<FallbackNs<N>> = undefined,
> = Record<
  keyof ToastService,
  <
    const TOpt extends TOptions,
    const TKey extends ParseKeys<N, TOpt, KPrefix>,
    Ret extends TFunctionReturn<N, AppendKeyPrefix<TKey, KPrefix>, TOpt>,
    const ActualOptions extends TOpt & InterpolationMap<Ret> = TOpt &
      InterpolationMap<Ret>,
  >(
    text: TKey | TKey[],
    options?: ActualOptions & SimplifiedToastOptions,
    defaultValue?: string,
  ) => void
>;

// eslint-disable-next-line react-refresh/only-export-components
export function toastsWithIntl<
  N extends Namespace,
  KPrefix extends KeyPrefix<FallbackNs<N>>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(_ns?: N): ToastServiceWithIntl<N, KPrefix> {
  return Object.fromEntries(
    Object.entries({
      toastSuccess,
      toastError,
    }).map(([key, service]) => [
      key,
      (text, options, defaultValue) => {
        return service(
          (defaultValue
            ? t(text, defaultValue, options)
            : t(
                // BUG: Cannot find a Typescript solution to solve this mapping without a any
                text as any,
                options,
              )) as string,
          options,
        );
      },
    ]),
  ) as ToastServiceWithIntl<N, KPrefix>;
}
