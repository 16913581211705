import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import RegisterForm from "./RegisterForm";
import { useEffect, useState } from "react";
import Logo from "../../../assets/logo-2MAX-large.png";
import CommonFooter from "../../../components/CommonFooter";
import { useKeepQueryParams } from "../../../router/routerHelpers";
import { t } from "i18next";
import Dialog from "../../../components/Dialog";
import presentation2mm from "../../../assets/video/2max_en_2_minutes.mp4";

const RegisterPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const keepQueryParams = useKeepQueryParams();
  const uid = searchParams.get("uid") ?? undefined;
  const seenSharedProfileDialog = searchParams.get("seen_spd");

  const [isOrganization, setIsOrganization] = useState(
    searchParams.has("uid") || location.pathname.includes("organization"),
  );
  const [showSharedProfileDialog, setShowSharedProfileDialog] = useState(
    !!uid && !seenSharedProfileDialog,
  );

  useEffect(() => {
    if (showSharedProfileDialog) {
      setSearchParams((p) => {
        p.set("seen_spd", "1");
        return p;
      });
    }
  }, [searchParams, setSearchParams, showSharedProfileDialog]);

  return (
    <div className="auth-screen">
      <img src={Logo} alt="2 Minutes Max" className="logo" />
      <div className="auth-card">
        <div className="side-image"></div>

        <div className="card_body">
          <div className="field-label">{t("register.PROFILE_TYPE")}</div>
          <div className="switch --block">
            <button
              className={isOrganization ? "" : "active"}
              onClick={() => setIsOrganization(false)}
            >
              {t("register.CANDIDATE")}
            </button>
            <button
              className={isOrganization ? "active" : ""}
              onClick={() => setIsOrganization(true)}
            >
              {t("register.RECRUITER")}
            </button>
          </div>
          <div className="lblock">
            <RegisterForm
              isOrganization={isOrganization}
              profileShareUid={uid}
            />
          </div>
          <div className="cblock --txt--center">
            <p className="auth-cta">{t("register.ALREADY_REGISTERED")}</p>
            <NavLink className="link" to={keepQueryParams("/login")}>
              {t("register.LOGIN")}
            </NavLink>
          </div>
        </div>
      </div>
      <CommonFooter />
      {showSharedProfileDialog && (
        <Dialog
          className="--branded"
          onClose={() => setShowSharedProfileDialog(false)}
        >
          <div>
            <div className="popup_head">
              <h2 className="popup_title">{t("auth:login.VIEW_PROFILE")}</h2>
            </div>
            <div className="popup_body">
              <video
                className="videoplayer"
                playsInline
                src={presentation2mm}
                controls
                onContextMenu={(e) => e.preventDefault()}
              />
              <div className="cblock --txt--center --pre-line">
                {t("organization:shared-profile-accepted")}
              </div>
            </div>
            <div className="popup_footer">
              <button
                className="btn --primary"
                onClick={() => setShowSharedProfileDialog(false)}
              >
                {t("auth:login.OK")}
              </button>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default RegisterPage;
