import { NavLink, useLoaderData } from "react-router-dom";
import { Capsule } from "../../../services/capsule/capsuleModel";
import { User } from "../../../services/user/userModel";
import CommonFooter from "../../../components/CommonFooter";
import CommonHeader from "../../../components/CommonHeader";
import SuspenseVideoPlayer from "../../../components/SuspenseVideoPlayer";
import { cx } from "../../../react-helpers/css";
import { useTranslation } from "react-i18next";

const CapsulePublicPage = () => {
  const { loggedUser, capsule, video, error } = useLoaderData() as {
    loggedUser?: User;
    capsule?: Capsule;
    video: Promise<Blob> | null;
    error?: {
      message: string;
      status: number;
    };
  };
  const { t } = useTranslation();

  return (
    <>
      <CommonHeader loggedUser={loggedUser} />
      <div className="layout_content --bg--organization">
        <div className="page-content container --wrap--m">
          {error ? (
            <div
              className={cx(["info", error.status === 404 ? "--error" : ""])}
            >
              {t(error.message)}
            </div>
          ) : (
            <>
              <div className="page_head lblock">
                <h1 className="page_title">
                  {capsule!.organization?.legalName}
                </h1>
                <p className="body">{capsule!.name}</p>
              </div>

              <div className="lrow --gap--l lblock--l">
                <div className="--stretch">
                  {video && (
                    <div className="video-container">
                      <SuspenseVideoPlayer
                        videoPromise={video}
                        thumbnail={capsule!.thumbnail}
                      />
                    </div>
                  )}

                  <div className="cblock lrow --center">
                    {capsule!.externalLink && (
                      <a
                        className="btn--white"
                        href={capsule!.externalLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("public:capsule.SEE_CAPSULE")}
                      </a>
                    )}
                    <NavLink to={"/register"} className="btn">
                      {t("public:capsule.CREATE_ACCOUNT")}
                    </NavLink>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <CommonFooter loggedUser={loggedUser} />
    </>
  );
};

export default CapsulePublicPage;
