import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const ReturnButton = ({
  forcedUrl,
  fallbackUrl,
  children,
}: {
  forcedUrl?: string;
  fallbackUrl?: string;
  children?: ReactNode;
}) => {
  const { t } = useTranslation();
  return (
    // BUG: If we navigate to the current route it register as previous page... No easy fix. No access to history.
    <Link
      className="link --back"
      to={
        forcedUrl ??
        ((window.history.state &&
          window.history.state.idx > 0 &&
          (-1 as any)) ||
          null) ??
        fallbackUrl ??
        "/"
      }
    >
      {children ?? t("common:GO_BACK")}
    </Link>
  );
};
