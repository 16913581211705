// eslint-disable-next-line no-console
export function debugWrapperBuider(logger = console.log) {
  return <
    Fn extends (...args: any[]) => any,
    Result extends ReturnType<Fn>,
    Params extends Parameters<Fn>,
  >(
    fn: Fn,
    label = "debugWrapper",
  ): ((...args: Params) => Result) => debugWrapper(fn, label, logger);
}

export function debugWrapper<
  Fn extends (...args: any[]) => any,
  Result extends ReturnType<Fn>,
  Params extends Parameters<Fn>,
>(
  fn: Fn,
  label = "debugWrapper",
  // eslint-disable-next-line no-console
  logger = console.debug,
): (...args: Params) => Result {
  return (...args) => {
    try {
      const result = fn(...args);

      logger(label, "args:", args, "result", result);
      return result;
    } catch (err) {
      logger(label, "args:", args, "error:", err);
      throw err;
    }
  };
}

export function debounce<Fn extends (...args: any[]) => any>(
  fn: Fn,
  debounceMs: number,
  maxDebounceMs = Infinity,
) {
  let timeoutId: ReturnType<typeof setTimeout>;
  let start: null | number = null;

  return function (this: any, ...args: Parameters<Fn>) {
    // Start the maxDebounceMs timer
    if (start === null) start = Date.now();

    // If the we debounced more than the max execute the function
    if (Date.now() - start < maxDebounceMs) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        start = null;
        return fn.apply(this, args);
      }, debounceMs);
    }
  } as Fn;
}

export function debugFunctionTimings<Fn extends (...args: any[]) => any>(
  fn: Fn,
  label = fn.name,
  // eslint-disable-next-line no-console
  logger = console.debug,
): (...args: Parameters<Fn>) => ReturnType<Fn> {
  return (...args) => {
    logger(`Call to ${label} begin`);
    const start = Date.now();
    let result;
    try {
      result = fn(...args);
      if (result instanceof Promise) {
        return result.then((res) => {
          logger(
            `Call to promise ${label} ended with success after ${
              Date.now() - start
            }ms`,
          );
          return res;
        });
      } else {
        logger(
          `Call to blocking ${label} ended with success after ${
            Date.now() - start
          }ms`,
        );
        return result;
      }
    } catch (e) {
      logger(
        `Call to ${label} ended with an error after ${Date.now() - start}ms`,
      );
      throw e;
    }
  };
}
