import { Navigate, RouteObject } from "react-router-dom";
import OrganizationDashboardPage from "../views/organization/OrganizationDashboardPage";
import OrganizationCandidatesProfilesPage from "../views/organization/OrganizationCandidatesProfilesPage";
import { userService } from "../services/user/userService";
import { getOrganizationRequest } from "../services/organization/organizationApi";
import { redirectOnCondition } from "./routerHelpers";
import ContactPage from "../views/contact/ContactPage";
import { authService } from "../services/auth/authService";
import OrganizationCompanyCapsules from "../views/organization/OrganizationCompanyCapsules";
import { promiseAllObject } from "../react-helpers/promise";
import {
  getAllCapsulesRequest,
  getCapsuleRequest,
} from "../services/capsule/capsuleApi";
import CapsulePage from "../views/organization/capsule/CapsulePage";
import RecordPage from "../views/record/RecordPage";
import { fetchRetriable } from "../react-helpers/fetch";

const { getLoggedUser } = userService();
const { isLoggedIn } = authService();

export const organizationRoutes: RouteObject = {
  path: "",
  children: redirectOnCondition(
    [
      {
        index: true,
        element: <OrganizationDashboardPage />,
        async loader() {
          const loggedUser = await getLoggedUser();

          const organization = await getOrganizationRequest(
            loggedUser.organizationId,
          );

          return {
            organization,
          };
        },
      },
      {
        path: "candidates",
        element: <OrganizationCandidatesProfilesPage />,
        async loader() {
          const loggedUser = await getLoggedUser();

          const organization = await getOrganizationRequest(
            loggedUser.organizationId,
          );

          return {
            organization,
          };
        },
      },
      {
        path: "capsules",
        element: <OrganizationCompanyCapsules />,
        async loader() {
          return promiseAllObject({
            loggedUser: getLoggedUser(),
            capsules: getAllCapsulesRequest(),
          });
        },
      },
      {
        path: "capsules/:capsuleId",
        element: <CapsulePage />,
        async loader({ params }) {
          const [loggedUser, capsule] = await Promise.all([
            getLoggedUser(),
            getCapsuleRequest(Number(params.capsuleId)),
          ]);

          let video = null;

          if (capsule.videoLink) {
            video = (async (videoLink) => {
              const data = await fetchRetriable(videoLink, {
                method: "GET",
                retry: 3,
              });
              return new Blob(
                [new Uint8Array(await data.arrayBuffer()).reverse()],
                {
                  type: "video/mp4",
                },
              );
            })(capsule.videoLink);
          }

          return {
            organization: await getOrganizationRequest(
              loggedUser.organizationId,
            ),
            capsule,
            video,
          };
        },
      },
      {
        path: "capsules/:capsuleId/record",
        loader: async ({ params }) => {
          return await promiseAllObject({
            capsule: getCapsuleRequest(Number(params.capsuleId)),
          });
        },
        errorElement: <Navigate to="./.." />,
        element: <RecordPage type="capsule" />,
      },
      {
        path: "contact",
        element: <ContactPage />,
        async loader() {
          return {
            loggedUser: isLoggedIn() ? await getLoggedUser() : null,
          };
        },
      },
    ],
    async () => {
      const loggedUser = await getLoggedUser();
      return !loggedUser.organizationId;
    },
    "/candidate",
  ),
};
