import { Concat, Deduplicate, Filter, Mut } from "./type";

export function cx<
  const T extends readonly unknown[],
  ConstRet extends Concat<Deduplicate<Filter<string, Mut<T>>>, " ">,
>(classNames: T): ConstRet {
  return [
    ...new Set(
      classNames.filter<string>(
        (className): className is string =>
          typeof className === "string" && className.length > 0,
      ),
    ).values(),
  ].join(" ") as ConstRet;
}
