import { Outlet } from "react-router-dom";
import { ScrollToAnchor } from "../components/ScrollToAnchor";

function App() {
  return (
    <div className="app-layout">
      <ScrollToAnchor />

      <Outlet />
    </div>
  );
}

export default App;
